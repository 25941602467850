<!--//https://primevue.org/-->

<template>

<div :class="$store.state.settings.currentTheme + '-theme'">

    <AnnucalVCard
     
        :prop_mode = props.prop_mode
        first_column_width = 20
        saveText = "savecalendar"
        :calendarColor = props.calendarColor
        :showCalendarName = true
        :showvCardActions = true
        :showDeleteButton = true
        :VcardWitdh = '440'
        :calendarName=props.header
        @unsubscribeMain="unsubscribeMain"
        @deleteElement="deleteElement"
        @saveEdit="saveEdit"
        @savenew="savenew"
        @close-menu="closeCalendarMenu"
       
  >
    <template v-slot:middle>
        <div>
    
        <!-------------------------------------------------------------------------------------------->
        <!------------------------------------ Calendar Name ----------------------------------------->
        <!-------------------------------------------------------------------------------------------->
    
           <div >
                <div style="
                        padding-top:24px
                    ">
                     <AnnucalTextfieldVue
                        labeltext="calendarNameInput"
                        v-model="calendarNameInput" 
                        textLength=30
                        :label = calendarname
                    />
                    </div>
            
                    <div 
                        v-if="mode=='edit' &&  editable==false"
                        class="vertical-center annucal-text-16px"
                        v-bind:style="{ color: calendarColor }"             
                    >
                        {{ kalendername }}
                    </div> 
           </div>
            
        <!-------------------------------------------------------------------------------------------->
        <!-------------------------------- Calendar Description -------------------------------------->
        <!-------------------------------------------------------------------------------------------->

        <div class="annucal-default-text-style"
             style="padding-bottom: 6px;"
                >
                {{description}}
        </div>

          <v-textarea  v-if="editable==true"
                density="compact"
                v-model=kalenderbeschreibung
                class="annucal-text-14px"
                bg-color="#F6F2EF"
                clearable
                variant="flat"  
            > 
          </v-textarea>


          <div v-if="mode=='edit' &&  editable==false"
            style="
                padding-left: 10px;
                padding-bottom: 25px;
            "
              class="annucal-text-8px"  
                                        
            >{{ kalenderbeschreibung }}</div> 
    
        </div>
    </template>

</AnnucalVCard>

</div> 
</template>
    
<script setup>
    
    import { ref, defineEmits, defineProps, onMounted, onBeforeUnmount, computed } from 'vue'
    import {db} from "../db";
    import {useStore} from "vuex";
    import calenderCore from "../composition/calenderCore";
    import { doc, updateDoc, deleteDoc } from "firebase/firestore";
    import AnnucalVCard from "./AnnucalVcard.vue"
    import AnnucalTextfieldVue from "./AnnucalTextfield.vue"
    import { v4 as uuidv4 } from 'uuid';

    import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const calendarname = ref(t('calendarname') )
const description = ref(t('description') )



    const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);

    const calendarNameInput = ref('')


    const iconPickerKey = ref(0);

    const forceRerender = () => {
        iconPickerKey.value += 1;
    };
    
    const props = defineProps({   
                    prop_mode: String,
                    header: String,
                    prop_kalendername: String,
                    prop_kalenderbeschreibung: String,
                    prop_radios: Boolean,
                    calendarColor: String,
                    prop_icon: String,
                    prop_calendarId: String,
                    prop_docId: String,
                    prop_creatorUID:String,
                    prop_externalCalendarLink:String,
                    prop_color:String,
                    prop_type:String
                }); 

  
    
    const emits = defineEmits(['closeCalendarMenu'])

    //const availableCalendarItems= computed({
    //    get() {
    //     return state.availablecalendars.items;
    //    },
    //  })

 

    const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })

    const events = computed({
        get() {
            return state.events.items;
        },
        })
    
    const store = useStore();
    
const { state } = store;
const calenderCore1 = calenderCore();

    const menueHeader = ref('')
    const mode = ref('')
    const editable = ref(false);
    const deleting = ref(false);
    const loading = ref(false);
    const docId = ref('')
    const calendarId = ref('')
    const kalendername = ref('')
    const externalCalendarLink = ref('')
    const creatorUID = ref('')
    const kalenderbeschreibung = ref('')
    const type = ref('')
    const radios= ref(true)
    const calendarColor = ref(null)
    //const calendarImagePath = ref('https://firebasestorage.googleapis.com/v0/b/annucaldev.appspot.com/o/default%2Fstandard_calendar_icon.png?alt=media&token=c55dce53-7204-4b48-b171-44fb0d687a9d')
    //var calendarImage = null
    const icon = ref('')

    const handleKeyPress = (event) => {
        console.log("props.mode: " + props.prop_mode)
    if (event.key === 'Enter') {
        if(props.prop_mode ==='edit'){
            saveEdit()
        }else{
            savenew()
        }
    }
    };

    onBeforeUnmount(() => {
        window.removeEventListener('keydown', handleKeyPress);
    });

    const initialize = () => {

        calendarNameInput.value = props.prop_kalendername
        //calenderCore1.setEditMode(props.date, props.calendarId)

        window.addEventListener('keydown', handleKeyPress);

        kalendername.value = props.prop_kalendername
        kalenderbeschreibung.value = props.prop_kalenderbeschreibung
        type.value = props.prop_type
        radios.value = props.prop_radios
        calendarColor.value = props.prop_calendarColor
        calendarId.value= props.prop_calendarId
        docId.value = props.prop_docId
        creatorUID.value = props.prop_creatorUID
        icon.value = props.prop_icon
        mode.value = props.prop_mode
        menueHeader.value = props.header
        if (props.prop_externalCalendarLink == undefined){
            externalCalendarLink.value = ''
        }else{
            externalCalendarLink.value = props.prop_externalCalendarLink
        }
        

        if(store.state.user.userID == creatorUID.value ){
            editable.value = true
            forceRerender()
        }
        else{
            menueHeader.value = 'Kalender deabonnieren?'
            editable.value = false
            forceRerender()
        }

        store.state.settings.savingModus = false

    }   

    onMounted(() => initialize());

  /* const labelStyles = computed(() => ({
      
      '--dot--color': getCalendarColor()
    ,
    }));*/
    
    //*****function changeCalendarImage(newImage){
    //*****    calendarImagePath.value = newImage.name
    //*****     calendarImage = newImage
    //***** }
    
    function closeCalendarMenu(){
        emits('closeCalendarMenu')
    }

    const availableColors = ref(['#6AD069','#4BA0C4','#5C70D7','#9D41D5','#D272DA'])
    

function getCalendarColor(){
  for(let c = 0; c < availableColors.value.length; ++c){
    var hasColor = false
    for (let i = 0; i < mycalendars.value.length; ++i){
      if(mycalendars.value[i].color === availableColors.value[c]){
        hasColor = true
      }
    }
    if(hasColor === false){
      return availableColors.value[c]
    }
  }
  return '#0000ff'
}
    
    async function savenew(){

        if(calendarNameInput.value == ""){
            store.state.settings.normlaErrorMessage = "Kalendername erforderlich"
            store.state.settings.savingModus = false
            return
        }

        store.state.settings.savingModus = true

        let newCalendar = {
            color: getCalendarColor(), //calendarColor.value, 
            //color: '#34ccff', - Farbe für Feiertage
            icon: 'ph-CalendarCheck', //icon.value,
            name: calendarNameInput.value,
            externalCalendarLink: '',//externalCalendarLink.value,
            description: kalenderbeschreibung.value,
            visible: true,
            private: radios.value,
            creatorUID: store.state.user.userID,
            creatorName: "Annucal",
            calendarImage:  "",
            type: type.value,
            specialday:'',
            country: '',
            subdivision: '',
            subdivisionCode: '',
            loadedPublicHolidays: false,
            events:[]
        }
    
        if(isAuthenticated.value == true){
            const newCalendarData = await store.dispatch("createCalendar", newCalendar);
            newCalendar.icon = newCalendarData.imageURL
            newCalendar.calendarId = newCalendarData.calendarId
            newCalendar.docId = newCalendarData.MyCalendarsdocId
            store.state.mycalendars.items.push(newCalendar)
        }else{
            const cId = uuidv4()
            newCalendar.calendarId = cId
            newCalendar.docId = cId
            store.state.mycalendars.items.push(newCalendar)
        }
        

        
        
        calenderCore1.updateCalender();
        emits('closeCalendarMenu')

        
    }

    async function saveEdit(){

        
        if(calendarNameInput.value == ""){
            store.state.settings.normlaErrorMessage = "Kalendername erforderlich"
            store.state.settings.savingModus = false
            return
        }

        store.state.settings.savingModus = true

        let newCalendar = {
            color: props.calendarColor, 
            icon: icon.value,
            name: calendarNameInput.value,
            description: kalenderbeschreibung.value,
            private: radios.value,
            docId: docId.value,
            userId: store.state.user.userID,
            calendarId: calendarId.value,
            type: type.value,
            externalCalendarLink: externalCalendarLink.value,
            specialday: '',
            country: '',
            subdivision: '',
            subdivisionCode: '',
            
        }
    
        if(isAuthenticated.value == true){
            await store.dispatch("updateCalendar", newCalendar);
        }
        
        

        //--------------- CHANGE LOCAL MYCALENDERS START ---------------------------
        
        for (let i = 0; i < mycalendars.value.length; i++) {
          if(mycalendars.value[i].calendarId == calendarId.value){
            mycalendars.value[i].color = props.calendarColor
            mycalendars.value[i].icon = icon.value
            mycalendars.value[i].name = calendarNameInput.value
            mycalendars.value[i].description = kalenderbeschreibung.value
            mycalendars.value[i].private = radios.value
            mycalendars.value[i].externalCalendarLink = externalCalendarLink.value
            mycalendars.value[i].type = type.value

            mycalendars.value[i].specialday= newCalendar.specialday,
            mycalendars.value[i].country= newCalendar.country,
            mycalendars.value[i].subdivision= newCalendar.subdivision,
            mycalendars.value[i].subdivisionCode= newCalendar.subdivisionCode
          }
        }

        //--------------- CHANGE LOCAL MYCALENDERS END ---------------------------

        //--------------- CHANGE LOCAL EVENTS START ------------------------------


        for (let i = 0; i < events.value.length; i++) {
          if(events.value[i].calendarId == calendarId.value){
            events.value[i].eventColor = calendarColor.value
            events.value[i].icon = icon.value
            events.value[i].name = kalendername.value
            events.value[i].description = kalenderbeschreibung.value
            events.value[i].private = radios.value
            events.value[i].externalCalendarLink = externalCalendarLink.value
            if(isAuthenticated.value == true){
                await updateDoc(doc(db, "calendars", events.value[i].calendarId, "events", events.value[i].eventidDB), newCalendar);
            }
          }
        }

        calenderCore1.updateCalender();

        //--------------- CHANGE LOCAL EVENTS END ------------------------------

        emits('closeCalendarMenu')
    }

    async function deleteElement(){

        let Calendar = {    
            calendarId: calendarId.value,
            docId: docId.value,
            creatorUID: store.state.user.userID,
        }

        if(isAuthenticated.value == true){
            await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
            await store.dispatch("deleteCalendarFromCalendars", Calendar);
        }
        
        unsubcribeToCalendarAfterDeleting(Calendar) 
        SetNewSelectedCalender()
        emits('closeCalendarMenu')

    }

    function SetNewSelectedCalender(){
        for (let i = 0; i < mycalendars.value.length; i++){
            if(mycalendars.value[i].externalCalendarLink == null || mycalendars.value[i].externalCalendarLink == "" || mycalendars.value[i].externalCalendarLink == undefined){
                store.state.settings.currentSelectedCalendar = mycalendars.value[i];
                store.state.settings.currentCalendarId = mycalendars.value[i].calendarId
                store.state.settings.currentCalendarColor = mycalendars.value[i].color 
                store.state.settings.currentCalendarIcon = mycalendars.value[i].icon
            }
        }
    }

    async function unsubscribeMain(){

        let Calendar = {
            calendarId: calendarId.value,
            docId: docId.value,
            creatorUID: store.state.user.userID,
        }
        await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
        unsubcribeToCalendar(Calendar)
        emits('closeCalendarMenu')
    }

    const availableCalendarItems= computed({
        get() {
         return state.availablecalendars.items;
        },
      })

    function removeEvents(calendarId){
        for(let index2 = 0; index2 < events.value.length; ++index2){
            if(events.value[index2].calendarId == calendarId){
            events.value.splice(index2,1);
            }
        }
    }

    async function unsubcribeToCalendar(result){

        loading.value = true;

        for (let index = 0; index < mycalendars.value.length; ++index){
            if(mycalendars.value[index].calendarId == result.calendarId){
            await deleteDoc(doc(db, "users", state.user.userID, "myCalendars", mycalendars.value[index].docId));
            mycalendars.value.splice(index, 1);
            }
        }

        for(let index2 = 0; index2 < events.value.length; ++index2){
            removeEvents(result.calendarId)
        }

        // Refresh Events Indexlist
        for (let index = 0; index < events.value.length; index++){
                events.value[index].index = index;
        }

        for (let index3 = 0; index3 < availableCalendarItems.value.length; ++index3){
             if(availableCalendarItems.value[index3].calendarId == result.calendarId){
                 availableCalendarItems.value[index3].subscribed = false;
             }
         }

        calenderCore1.updateCalender();
        
        result.subscribed = false;

        loading.value = false;

    }

async function unsubcribeToCalendarAfterDeleting(result){

    deleting.value = true;

    for (let index = 0; index < mycalendars.value.length; ++index){
        if(mycalendars.value[index].docId == result.docId){
            mycalendars.value.splice(index, 1);
        }
    }

    for(let index2 = 0; index2 < events.value.length; ++index2){
        if(events.value[index2].calendarId == result.docId){
            events.value.splice(index2,1);
        }
    }

    
    calenderCore1.updateCalender();

    deleting.value = false;

}

   
    
    </script>
    
<style scoped>

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.image{
    padding-top:30px;
    transition: transform .2s; /* Animation */
}

.image:hover{
    transform: scale(1.2);
    cursor: pointer;
}

.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--dot--color);
  margin-top: 5px;
  margin-left: 15px;
}

</style>
    