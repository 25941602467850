<template>

  <!-------------------------------------------------------------------------------------------->
  <!----------------------------------  ADD NEW PUBLIC HOLIDAY --------------------------------->
  <!-------------------------------------------------------------------------------------------->

      <div  
        v-if="showDeleteButton == false"
        style="
          height: 380px;
          "
      >
        <AnnucalVCard
        ref="vCardContainer"
        :calendarName=headline
        saveText = "savecalendar"
        :showCalendarName = true
        :showvCardActions = true
        :VcardWitdh = '440'
        prop_mode="new"
        @loaded="focusCountryInput"
        @unsubscribeMain="unsubscribeMain"
        @deleteElement="deleteElement"
        @saveEdit="saveEdit"
        @savenew="savenew"
        @close-menu="closeMenu"
      >
      <template v-slot:middle>

      <!-------------------------------------------------------------------------------------------->
      <!----------------------------- Search for Country and Subdivision --------------------------->
      <!-------------------------------------------------------------------------------------------->

            <div style="padding-top: 25px;">
              <AnnucalTextfieldVue
                  tabindex="1"
                  v-model="countryFilter"
                  :label="entercountry"
                  :placeholder=entercountry
                  @input="filterCountries"
                  showCounter=false            
                />
            </div>
               
              <v-list v-if="filteredCountries.length" class="autocomplete-list" tabindex="2">
                <v-list-item 
                  v-for="country in filteredCountries"
                  :key="country.CountryCode"
                  @click="selectCountry(country)"
                  class="autocomplete-item custom-list-item"
                  style="font-size: 16px;"
                >
                  {{ country.Country }}
                </v-list-item>
              </v-list>

                <AnnucalTextfieldVue style="margin-top: 16px;"
                  
             tabindex="3"
                  v-if="subdivisions.length"
                  v-model="subdivisionFilter"
                  :label=enterSubdivision
                  :placeholder=enterSubdivision
                  @input="filterSubdivisions"
                  showCounter=false
                  ref="subdivisionInput"
                />
                <v-list 
                densitiy="compact"
                border=false
                  v-if="filteredSubdivisions.length" 
                  class="autocomplete-list no-divider" 
                  tabindex="4">
                  <v-list-item
                    v-for="subdivision in filteredSubdivisions"
                    :key="subdivision.SC"
                    @click="selectSubdivision(subdivision)"
                    class="autocomplete-item custom-list-item"
                    style="font-size: 16px;"
                  >
                  {{ subdivision.SN }}
                  </v-list-item>
                </v-list>
            
         
          

          <!--<v-row justify="center" class="mt-4">
            <v-card outlined style="width: 250px;">
              <v-card-text>
                <p v-if="selectedCountry">Ausgewähltes Land: {{ selectedCountry.CountryCode }}</p>
                <p v-if="selectedSubdivision">Ausgewählte Unterregion: {{ selectedSubdivision }}</p>
              </v-card-text>
            </v-card>
          </v-row>-->


        
       
      </template>
  
    </AnnucalVCard> 
      </div>
   
      
      
  <!-------------------------------------------------------------------------------------------->
  <!---------------------------------EDIT/DELETE PUBLIC HOLIDAY -------------------------------->
  <!-------------------------------------------------------------------------------------------->

    <div v-if="showDeleteButton == true">

      <AnnucalVCard 
        showDeleteButton = true
        saveText = "savecalendar"
        :showCalendarName = true
        :showvCardActions = true
        :VcardWitdh = '440'
        :prop_mode="props.prop_mode"
        @unsubscribeMain="unsubscribeMain"
        @deleteElement="deleteElement"
        @saveEdit="saveEdit"
        @savenew="savenew"
        @close-menu="closeMenu"
        :calendarName=headline
        style="
            margin-left:12px;
            margin-right:8px;
            margin-top:82px;
        "
    >
        <template v-slot:middle>

          <!-------------------------------------------------------------------------------------------->
      <!----------------------------- Search for Country and Subdivision --------------------------->
      <!-------------------------------------------------------------------------------------------->

      <div style="padding-top: 25px;">
              <AnnucalTextfieldVue
                  tabindex="1"
                  v-model="countryFilter"
                  :label="entercountry"
                  :placeholder=entercountry
                  @input="filterCountries"
                  showCounter=false            
                />
            </div>
               
              <v-list v-if="filteredCountries.length" class="autocomplete-list" tabindex="2">
                <v-list-item 
                  v-for="country in filteredCountries"
                  :key="country.CountryCode"
                  @click="selectCountry(country)"
                  class="autocomplete-item custom-list-item"
                  style="font-size: 16px;"
                >
                  {{ country.Country }}
                </v-list-item>
              </v-list>

                <AnnucalTextfieldVue style="margin-top: 16px;"
                  
             tabindex="3"
                  v-if="subdivisions.length"
                  v-model="subdivisionFilter"
                  :label=enterSubdivision
                  :placeholder=enterSubdivision
                  @input="filterSubdivisions"
                  showCounter=false
                  ref="subdivisionInput"
                />
                <v-list 
                densitiy="compact"
                border=false
                  v-if="filteredSubdivisions.length" 
                  class="autocomplete-list no-divider" 
                  tabindex="4">
                  <v-list-item
                    v-for="subdivision in filteredSubdivisions"
                    :key="subdivision.SC"
                    @click="selectSubdivision(subdivision)"
                    class="autocomplete-item custom-list-item"
                    style="font-size: 16px;"
                  >
                  {{ subdivision.SN }}
                  </v-list-item>
                </v-list>
            
         
          

          <!--<v-row justify="center" class="mt-4">
            <v-card outlined style="width: 250px;">
              <v-card-text>
                <p v-if="selectedCountry">Ausgewähltes Land: {{ selectedCountry.CountryCode }}</p>
                <p v-if="selectedSubdivision">Ausgewählte Unterregion: {{ selectedSubdivision }}</p>
              </v-card-text>
            </v-card>
          </v-row>-->
    
        
        </template>
  
        </AnnucalVCard> 
      </div>

  
    
</template>
    
<script setup>
  
    import { ref, defineEmits, onMounted, onBeforeUnmount, defineProps, computed } from 'vue';
    import countriesDataDE from '../assets/countries_with_subdivisions_de.json';
    import countriesDataEN from '../assets/countries_with_subdivisions_en.json';
    import countriesDataShoolHolidaysDE from '../assets/countries_with_subdivisions_open_holiday_de.json';
    import countriesDataShoolHolidaysEN from '../assets/countries_with_subdivisions_open_holiday_en.json';
    import AnnucalVCard from "./AnnucalVcard.vue"
    import AnnucalTextfieldVue from "./AnnucalTextfield.vue"
    import {useStore} from "vuex";
    import calenderCore from "../composition/calenderCore";
    import { v4 as uuidv4 } from 'uuid';
    import { useI18n } from 'vue-i18n'

    const store = useStore();
    const { state } = store;

    const { t } = useI18n()
    const i18nLocale = useI18n();

    const props = defineProps({
        prop_mode: {
          type: String,
          required: true
        },
        prop_calendarId: String,
        prop_docId: String,
        allowDelete: {
          type: Boolean,
          required: true 
        },
        calendarInfo:{
          type: Object,
          required: true
        },
        headline: {
          type: String,
          required: true
        },
        calenderType:{
          type: String,
          required: true
        }
    });

    const entercountry = ref(t('entercountry') )
    const enterSubdivision = ref(t('publicholidays'))
      //const shoolHolidays = ref(t('schoolholidays'))
      //const entercountry = ref(t('entercountry'))

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        if(props.prop_mode ==='edit'){
          if(selectedCountry.value != null && selectedSubdivision.value != null){
            if(store.state.settings.savingModus == false){
              saveEdit()
            }
          } 
        }else{
          if(selectedCountry.value != null && selectedSubdivision.value != null){
            if(store.state.settings.savingModus == false){
              savenew()
            }
          }
        }
      }
    };

    const headline = ref('')
    //countriesDataShoolHolidaysDE
//selectedSpecialdayId.value
    const countries = getCorrectCountriesFile()
    
    const selectedCountry = ref(null);
    const subdivisions = ref([]);
    const selectedSubdivision = ref(null);
    
    const countryFilter = ref('');
    const subdivisionFilter = ref('');
    const filteredCountries = ref(countries);
    const filteredSubdivisions = ref([]);
    const firstfilteredSubdivisions = ref([]);

    const countryInput = ref(null);
    const subdivisionInput = ref(null);

    const focusCountryInput = () => {
      if (countryInput.value) {
        countryInput.value.focus();
      }
    };

    const selectCountry = (country) => {
      selectedCountry.value = country;
      countryFilter.value = country.Country;
      updateSubdivisions();
      filteredCountries.value = [];
      if(country.CountryCode == 'DE'){
         enterSubdivision.value = t('enterbundesland')
      }else if(country.CountryCode == 'AT'){
         enterSubdivision.value = t('enterbundesland')
      }else if(country.CountryCode == 'CH'){
         enterSubdivision.value = t('enterkanton')
      }else if(country.CountryCode == 'US'){
         enterSubdivision.value = t('enterstate')
      }
      else{
        enterSubdivision.value = t('enterregion')
      }
      //nextTick(() => {
        // Set focus to the subdivision input field
      //  subdivisionInput.value.focus();
    // });
    };

  const selectSubdivision = (subdivision) => {
    selectedSubdivision.value = subdivision.SN;
    selectedSubdivisionCode.value = subdivision.SC;
    subdivisionFilter.value = subdivision.SN;
    filteredSubdivisions.value = [];
  };

  const updateSubdivisions = () => {
    subdivisions.value = selectedCountry.value ? selectedCountry.value.Subdivisions : [];
    firstfilteredSubdivisions.value = subdivisions.value;

    filteredSubdivisions.value = firstfilteredSubdivisions.value.filter(
      (item, index, self) => 
        index === self.findIndex((t) => t.SN === item.SN)
    );


    selectedSubdivision.value = null;
    selectedSubdivisionCode.value = null;
    subdivisionFilter.value = '';
  };

  const filterCountries = () => {
    const filterText = countryFilter.value.toLowerCase();
    filteredCountries.value = countries.filter((country) =>
      country.Country.toLowerCase().includes(filterText)
    );
    selectedCountry.value = null;
    subdivisions.value = [];
    filteredSubdivisions.value = [];
    selectedSubdivision.value = null;
  };

  const filterSubdivisions = () => {
    const filterText = subdivisionFilter.value.toLowerCase();
    firstfilteredSubdivisions.value = subdivisions.value.filter((subdivision) =>
      subdivision.SN.toLowerCase().includes(filterText)
    );

    

  filteredSubdivisions.value = firstfilteredSubdivisions.value.filter(
    (item, index, self) => 
      index === self.findIndex((t) => t.SN === item.SN)
  );

    selectedSubdivision.value = null;
    selectedSubdivisionCode.value = null;
  };


      
      const calenderCore1 = calenderCore();
      const showDeleteButton = ref(false)
    
     
    

    
    const emit = defineEmits(['close-menu'])

    const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
    
    const selectedSpecialdayId = ref('2') //2 Feiertage, 3 Schulferien
    const selectedSpecialday = ref('Feiertage'); // Feiertage ist standardmäßig ausgewählt
    
    function closeMenu(){
        emit('close-menu')
    }

    onBeforeUnmount(() => {
      if (vCardContainer.value?.$el) {
        vCardContainer.value.$el.removeEventListener('keydown', focusTrap);
        window.removeEventListener('keydown', handleKeyPress);
      }
    });

const vCardContainer = ref(null); // Referenz zur v-card

function getCorrectCountriesFile(){
  if(i18nLocale.locale.value == "de" && props.calenderType == 2 ){
    return countriesDataDE
  }else if(i18nLocale.locale.value == "de" && props.calenderType == 3){
    return countriesDataShoolHolidaysDE
  }else if(i18nLocale.locale.value == "en" && props.calenderType == 2){
    return countriesDataEN
  } 
  else if(i18nLocale.locale.value == "en" && props.calenderType == 3){
    return countriesDataShoolHolidaysEN
  } 
}

function focusTrap(event) {
  if (event.key === 'Tab') {
    event.preventDefault();
    const focusableElements = vCardContainer.value.$el.querySelectorAll(
      'input, button, [tabindex]:not([tabindex="-1"])'
    );
    const focusableArray = Array.from(focusableElements);
    const currentIndex = focusableArray.indexOf(document.activeElement);

    let nextIndex;
    if (event.shiftKey) {
      nextIndex = currentIndex === 0 ? focusableArray.length - 1 : currentIndex - 1;
    } else {
      nextIndex = currentIndex === focusableArray.length - 1 ? 0 : currentIndex + 1;
    }

    focusableArray[nextIndex]?.focus();
  }
}


    
  onMounted(() => {
    initialize()
  
  });
  
  const initialize = () => {

    
    store.state.settings.AnnucalVcardHeadline = props.headline
    if(props.calendarInfo != null){
      selectCountry(filteredCountries.value.find(ev => ev.Country === props.calendarInfo.country))
      selectedSubdivision.value = props.calendarInfo.subdivision;
      selectedSubdivisionCode.value = props.calendarInfo.subdivisionCode;
      subdivisionFilter.value = props.calendarInfo.subdivision;
      filteredSubdivisions.value = [];
   
      
    }

    window.addEventListener('keydown', handleKeyPress);
    if (vCardContainer.value?.$el) {
      vCardContainer.value.$el.addEventListener('keydown', focusTrap);
    }
      headline.value = t(props.headline)
      store.state.settings.Log_Status = ''
      store.state.settings.AnnucalSaveText = t('save')
      calendarId.value= props.prop_calendarId
      docId.value = props.prop_docId
      store.state.settings.savingModus = false
      showDeleteButton.value = props.allowDelete
      
        selectedSpecialdayId.value = props.calenderType
        selectedSpecialday.value = props.headline
        //selectedCountry.value = props.calendarInfo.country
        //selectedCountry.value  = items.value.find(country => country.Country === props.calendarInfo.country);
        //selectedCountryName.value = props.calendarInfo.country
        //selectedSubdivisionName.value = props.calendarInfo.subdivision
        //selectedSubdivisionCode.value = props.calendarInfo.subdivisionCode
      
  } 
  
 

    //const selectedCountryName = ref('');
    //const selectedSubdivisionName = ref('');
    
    const selectedSubdivisionCode = ref(null);
    
 

    const docId = ref('')
    const calendarId = ref('')
    const deleting = ref(false);

    const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })

    const events = computed({
        get() {
            return state.events.items;
        },
        })

async function deleteElement(){

  let Calendar = {    
      calendarId: calendarId.value,
      docId: docId.value,
      prop_docId: String,
      creatorUID: store.state.user.userID,
  }
  if(isAuthenticated.value === true){
    await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
    await store.dispatch("deleteCalendarFromCalendars", Calendar);
  }
  
  unsubcribeToCalendarAfterDeleting(Calendar) 
  SetNewSelectedCalender()
  store.dispatch("removePublicCalendarFromList", calendarId.value);
  emit('close-menu')

}





function SetNewSelectedCalender(){
        for (let i = 0; i < mycalendars.value.length; i++){
            if(mycalendars.value[i].externalCalendarLink == null || mycalendars.value[i].externalCalendarLink == "" || mycalendars.value[i].externalCalendarLink == undefined){
                store.state.settings.currentSelectedCalendar = mycalendars.value[i];
                store.state.settings.currentCalendarId = mycalendars.value[i].calendarId
                store.state.settings.currentCalendarColor = mycalendars.value[i].color 
                store.state.settings.currentCalendarIcon = mycalendars.value[i].icon
            }
        }
  }

async function unsubcribeToCalendarAfterDeleting(result){

  deleting.value = true;

  for (let index = 0; index < mycalendars.value.length; ++index){
      if(mycalendars.value[index].docId == result.docId){
          mycalendars.value.splice(index, 1);
      }
  }

  for(let index2 = 0; index2 < events.value.length; ++index2){
      if(events.value[index2].calendarId == result.docId){
          events.value.splice(index2,1);
      }
  }

  calenderCore1.updateCalender();
  deleting.value = false;

}

function getCorrectSubdivisionForOpenHolidays(subdivision){
  switch(subdivision) {
  case 'AT-1':
    return 'AT-BL'
  case 'AT-2':
    return 'AT-KÄ'
  case 'AT-3':
    return 'AT-NÖ'
  case 'AT-4':
    return 'AT-OÖ'
  case 'AT-5':
    return 'AT-SB'
  case 'AT-6':
    return 'AT-SM'
  case 'AT-7':
    return 'AT-TI'
  case 'AT-8':
    return 'AT-VA'
  case 'AT-9':
    return 'AT-WI'
  default:
    // code block
  }
  return subdivision
}
  

async function savenew(){

  try{
    store.state.settings.savingModus = true

    /*
      prop_type="1"
      1 = Internal Kalender
      2 = Public Holiday
      3 = School Holidays
      4 = Subscribed ICal from Google, Apple, and so on ...
    */
    
    let newCalendar = {
        color: '#A39F96', //calendarColor.value, 
        icon: 'ph-CalendarCheck', //icon.value,
        name: selectedSpecialday.value,
        externalCalendarLink: '',//externalCalendarLink.value,
        description: '',
        visible: true,
        private: true,
        creatorUID: store.state.user.userID != undefined ?  store.state.user.userID : "notSignedIn",
        creatorName: "Annucal",
        //calendarImage:  calendarImage,
        type: selectedSpecialdayId.value,
        specialday:selectedSpecialday.value,
        country: selectedCountry.value.Country,
        subdivision: selectedSubdivision.value,
        subdivisionCode: getCorrectSubdivisionForOpenHolidays(selectedSubdivisionCode.value) ,
        loadedPublicHolidays: false,
        events:[]
    }
    
    if(isAuthenticated.value == true){
      const newCalendarData = await store.dispatch("createCalendar", newCalendar);
      newCalendar.icon = newCalendarData.imageURL
      newCalendar.calendarId = newCalendarData.calendarId
      newCalendar.docId = newCalendarData.MyCalendarsdocId
      store.state.mycalendars.items.push(newCalendar) 
    }else{
      const cId = uuidv4()
      newCalendar.calendarId = cId
      newCalendar.docId = cId
      store.state.mycalendars.items.push(newCalendar)
    }

      await store.dispatch('getPublicHolidaysFromApi', { userId: store.state.user.userID, localCalendars: store.state.mycalendars.items, language: ref(t('selectedlanguage') ) });
      //await store.dispatch("getPublicHolidaysFromApi", store.state.user.userID, mycalendars.value)
      //store.state.mycalendars.items = store.state.storedmycalendars.items
      store.state.availablecalendars.items = store.state.storedavailablecalendars.items
      store.state.events.items = store.state.storedevents.items
      await store.dispatch("getmydaysWithEventsFromDB", {
      events: store.state.events.items, 
      myCalendars: store.state.mycalendars.items, 
      calender: store.state.calender.items})
   

    
    calenderCore1.updateCalender()
    
    
    emit('close-menu')

  }catch(error){
    console.log("Error - saveNew - AnnucalHolidays: " + error)
  }
}

function removePublicHolidayEvents(calendarId){

   // delete event from eventlist
   for(let index = 0; index < store.state.storedevents.items.length; ++index){
    for (let index = store.state.storedevents.items.length - 1; index >= 0; --index) {
    if (store.state.storedevents.items[index].calendarId === calendarId) {
      store.state.storedevents.items.splice(index, 1);
    }
  }
  }


  // Refresh Events Indexlist
  for(let index = 0; index < store.state.storedevents.items.length; ++index){
      store.state.storedevents.items[index].index = index;
  }

  // Write each event index in the events of each mycalendars
  for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
    store.state.mycalendars.items[calenderIndex].events = []
      for (let eventIndex = 0; eventIndex < store.state.storedevents.items.length; eventIndex++){
          if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.storedevents.items[eventIndex].calendarId){
            store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
          }
      } 
  } 
}

async function saveEdit(){

  store.state.settings.savingModus = true
  removePublicHolidayEvents(calendarId.value)
  store.dispatch("removePublicCalendarFromList", calendarId.value);

  let newCalendar = {
      
      docId: docId.value,
      userId: store.state.user.userID,
      calendarId: calendarId.value,
      type: selectedSpecialdayId.value,
      specialday: selectedSpecialday.value,
      country: selectedCountry.value.Country,
      subdivision: selectedSubdivision.value,
      subdivisionCode: selectedSubdivisionCode.value,
      name: props.calendarInfo.name,
      description: props.calendarInfo.description,
      icon: props.calendarInfo.icon,
      private: props.calendarInfo.private,
      color: props.calendarInfo.color,
      externalCalendarLink:"",
      loadedPublicHolidays: false,
      events: []
  }

  if(isAuthenticated.value == true){
    await store.dispatch("updateCalendar", newCalendar);
   // store.state.mycalendars.items.push(newCalendar) 
  }
  //else{
  //  const cId = uuidv4()
  //  newCalendar.calendarId = cId
  //  newCalendar.docId = cId
  //  store.state.mycalendars.items.push(newCalendar)
  //}
  
    //--------------- CHANGE LOCAL MYCALENDERS START ---------------------------

    for (let i = 0; i < mycalendars.value.length; i++) {
      if(mycalendars.value[i].calendarId == calendarId.value){
        mycalendars.value[i].country= selectedCountry.value.Country
        mycalendars.value[i].subdivision= selectedSubdivision.value
        mycalendars.value[i].subdivisionCode= selectedSubdivisionCode.value
        mycalendars.value[i].loadedPublicHolidays = false
        mycalendars.value[i].events = []
      }
    }

  //--------------- CHANGE LOCAL MYCALENDERS END ---------------------------

    await store.dispatch('getPublicHolidaysFromApi', { userId: store.state.user.userID, localCalendars: mycalendars.value, language: ref(t('selectedlanguage') ) });
    //await store.dispatch("getPublicHolidaysFromApi", store.state.user.userID, store.state.mycalendars.items)
    //store.state.mycalendars.items = store.state.storedmycalendars.items
    store.state.availablecalendars.items = store.state.storedavailablecalendars.items
    store.state.events.items = store.state.storedevents.items
    await store.dispatch("getmydaysWithEventsFromDB", {
        events: store.state.events.items, 
        myCalendars: mycalendars, 
        calender: store.state.calender.items
    })

       // Refresh Events Indexlist
    for(let index = 0; index < store.state.events.items.length; ++index){
        store.state.events.items[index].index = index;
    }

    // Write each event index in the events of each mycalendars
    for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
      store.state.mycalendars.items[calenderIndex].events = []
        for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
            if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
              store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
            }
        } 
    } 

    calenderCore1.updateCalender()
    

  emit('close-menu')
}
  
  
</script>
    
  <style scoped>

.text-style{
    font-size: 13px;
    font-weight: 400;
    color:var(--standard-text-color);
    letter-spacing: -0.6px;
}
  

  
  .second-row{
      padding-top: 40px;
  }
  
  .third-row{
      padding-top: 4px;
  }
  
  .list-text{
    font-size: 14px;
  }
  
  .custom-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .custom-list-item {
    padding: 0 5px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .custom-list-item:hover {
    background-color: #f0f0f0;
  }

  .autocomplete-list {
    border: 1px solid #ddd;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
  }

.autocomplete-item {
  cursor: pointer;
}

.no-border {
  border: none;
  box-shadow: none;
}

.custom-list-item {
  height: 12px;
}

.no-divider .v-list-item + .v-list-item {
  border-top: none; /* Entfernt die Trennlinie zwischen Listenelementen */
}
  
  
  </style>
    
  
