<template>


    <!-------------------------------------------------------------------------------------------->
    <!------------------------------------ Annucal VCard ----------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div style="
            margin-right: -32px;
            margin-top: -4px;
        ">
        <AnnucalVCard
            @loaded="focusCountryInput"
            ref="vCardContainer"
            :showCalendarName = true
            :showvCardActions = true
            :VcardWitdh = '480'
            :calendarName = store.state.settings.AnnucalVcardHeadline
            @close-menu="closeMenu"
            @savenew="login"
            @saveEdit="logout"
            @register="register"
            @resetPassword = "resetPassword"
        >
            <!-------------------------------------------------------------------------------------------->
            <!------------------------------  Middle Slot - Annucal VCard -------------------------------->
            <!-------------------------------------------------------------------------------------------->

            <template v-slot:middle>

                <v-divider style="margin-top:8px"></v-divider>

                <!-------------------------------------------------------------------------------------------->
                <!-----------------------------------  Log in ------------------------------------------------>
                <!-------------------------------------------------------------------------------------------->
                <div 
                    v-if="!isAuthenticated && this.$store.state.settings.showForgotPassword == false 
                    && this.$store.state.settings.showRegister==false
                    "
                >
                <!-----------------------------------  Email ------------------------------------------------>
                    <div style="padding-top: 32px">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInEmail" 
                            label = "Email"
                            labelType="email"
                            textLength="120"
                        />
                    </div>

                    <!-----------------------------------  Password ------------------------------------------------>
                    <div style="
                        padding-top: 16px;
                        padding-bottom: 4px;
                    ">
                        <AnnucalTextfieldVue
                         
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInPassword" 
                            :label = password
                            labelType="password"
                            textLength="60"
                        />
                    </div>

                   
                    <div style="
                      padding-bottom: 4px;
                      padding-right: 4px;
                      ">
                        <div class="text-grid">
                           <!-------------------------------  No Account / Register ----------------------------------------->
                            <div class="text-grid-left annucal-default-text-style">{{ $t("No account yet") }}?</div>
                            <div class="text-grid-right annucal-default-text-style-bold" @click="openRegister" style=" justify-self: end;"> 
                                {{ $t("Register now") }}
                            </div> 
                            <!-------------------------------  Forgot Password ----------------------------------------->
                            <div style="padding-top: 8px;" class="text-grid-left annucal-default-text-style">{{ $t("Forgot password") }}?</div>
                            <div style="padding-top: 8px;"  class="text-grid-right annucal-default-text-style-bold" @click="openForgotPassword"> 
                              {{ $t("Reset now") }}
                            </div>     
                        </div>
                    </div>
                     
                </div>

                <!-------------------------------------------------------------------------------------------->
                <!---------------------------------------- Register ------------------------------------------>
                <!-------------------------------------------------------------------------------------------->
                <div v-if="!isAuthenticated && this.$store.state.settings.showRegister == true">
            
                  <!-----------------------------------  Email ------------------------------------------------>
                  <div style="padding-top: 32px">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInEmail" 
                            label = "Email"
                            labelType="email"
                            textLength="120"
                        />
                    </div>

                    <!-----------------------------------  Password ------------------------------------------------>
                    <div style="
                        padding-top: 16px;
                        padding-bottom: 4px;
                    ">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInPassword" 
                            label = "Passwort"
                            labelType="password"
                            textLength="60"
                        />
                    </div>

                        <!-----------------------------------  Password confirmation ------------------------------------------------>
                        <div style="
                        padding-top: 16px;
                        padding-bottom: 4px;
                    ">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInPasswordConfirmation" 
                            label = "Passwort wiederholen"
                            labelType="password"
                            textLength="60"
                        />
                    </div>

                    <div style="
                      padding-bottom: 4px;
                      padding-right: 4px;
                      ">
                        <div class="text-grid ">
                           <!-------------------------------  No Account / Register ----------------------------------------->
                            <div class="text-grid-left annucal-default-text-style">{{ $t("Already registered?") }}</div>
                            <div class="text-grid-right annucal-default-text-style-bold" @click="openLogin" style=" justify-self: end;"> 
                              {{ $t("Log in now") }}
                            </div> 
                           
                        </div>
                    </div>

                </div>

                <!-------------------------------------------------------------------------------------------->
                <!-----------------------------------  Reset Password ---------------------------------------->
                <!-------------------------------------------------------------------------------------------->

                <div v-if="!isAuthenticated && this.$store.state.settings.showForgotPassword == true  && this.$store.state.settings.resetPasswordSuccess == false" >

                  <!-----------------------------------  Email ------------------------------------------------>
                  <div style="padding-top: 32px">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.resetPasswordEmail" 
                            label = "Email"
                            labelType="email"
                            textLength="120"
                        />
                  </div>

                  </div>

                <!-------------------------------------------------------------------------------------------->
                <!-----------------------------------  Reset Password Success--------------------------------->
                <!-------------------------------------------------------------------------------------------->
            
                <div v-if="!isAuthenticated && this.$store.state.settings.resetPasswordSuccess == true">
                
                  <div 
                    style="padding-top: 32px"
                    class="annucal-default-text-style"
                  >  {{ $t("emailsent") + store.state.settings.resetPasswordEmail }} </div>
                
                </div>

                <!-------------------------------------------------------------------------------------------->
                <!-----------------------------------  Log out ----------------------------------------------->
                <!-------------------------------------------------------------------------------------------->
                <div v-if="isAuthenticated && this.$store.state.settings.showForgotPassword == false" 
                    style="
                        padding-top: 22px;
                        padding-bottom: 20px;
                    "
                >
                <span class="annucal-default-text-style"> {{ $t("logouttext1") }}</span>
                <span class="annucal-default-text-style-bold"> {{ user.email }}</span> 

                <div class="annucal-default-text-style"> {{ $t("logouttext2") }}</div> 
                </div>


            </template>

        </AnnucalVCard>
    </div>

    
</template>
  
<script setup>
import { ref, computed, onMounted, defineProps, defineEmits, onBeforeUnmount } from 'vue';
//import calenderCore from '../composition/calenderCore';
//import useAuth from '../composition/useAuth';
import { useStore } from 'vuex';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import AnnucalVCard from "./AnnucalVcard.vue"
import AnnucalTextfieldVue from "./AnnucalTextfield.vue"
import router from '@/router'; 
import { useI18n } from 'vue-i18n'

import { shutdown } from '@intercom/messenger-js-sdk';

const { t } = useI18n()

const password = ref(t('password') )

const props = defineProps({
    Headline:{
        type: String,
        required: true
    }
});

const countryInput = ref(null);

const focusCountryInput = () => {
      if (countryInput.value) {
        countryInput.value.focus();
      }
    };


const emit = defineEmits(['closeDialog']);

function closeMenu(){
  emit('closeDialog');
}



//const toast = useToast();
const store = useStore();
const { state } = store;


//const isProcessing = computed(() => state.user.auth.isProcessing);
const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
const user = computed(() => state.user.data);

const form = ref({
  email: '',
  password: '',
  username: '',
  passwordConfirmation: '',
});


const message = ref(null);
const error = ref(null);
const emailSending = ref(false);


const Headline = ref("nothing")

onBeforeUnmount(() => {
      if (vCardContainer.value?.$el) {
        vCardContainer.value.$el.removeEventListener('keydown', focusTrap);
        window.removeEventListener('keydown', handleKeyPress);
        if( store.state.settings.Log_Status  == "Register"){
          Headline.value = t("Register")
        }
      }
    });

const vCardContainer = ref(null); // Referenz zur v-card

function focusTrap(event) {
  if (event.key === 'Tab') {
    event.preventDefault();
    const focusableElements = vCardContainer.value.$el.querySelectorAll(
      'input, button, [tabindex]:not([tabindex="-1"])'
    );
    const focusableArray = Array.from(focusableElements);
    const currentIndex = focusableArray.indexOf(document.activeElement);

    let nextIndex;
    if (event.shiftKey) {
      nextIndex = currentIndex === 0 ? focusableArray.length - 1 : currentIndex - 1;
    } else {
      nextIndex = currentIndex === focusableArray.length - 1 ? 0 : currentIndex + 1;
    }

    focusableArray[nextIndex]?.focus();
  }
}

const handleKeyPress = (event) => {
     if (event.key === 'Enter') {
      if( !isAuthenticated.value && 
          store.state.settings.showForgotPassword == false &&
          store.state.settings.showRegister==false){
            if(store.state.settings.savingModus == false){
              login()
            }
          }
     
    }
  }


onMounted(() => initialize());

const initialize = () => {
    store.state.settings.resetPasswordSuccess = false
    store.state.settings.resetPasswordEmail = ""
    store.state.settings.logInEmail=""
    store.state.settings.logInPassword=""
    store.state.settings.logInPasswordConfirmation=""
    store.state.settings.emailErrorMessage = ""
    store.state.settings.passwordErrorMessage = ""
    store.state.settings.savingModus = false
    store.state.settings.AnnucalVcardHeadline = props.Headline
    store.state.settings.logInEmail = ""
    store.state.settings.logInPassword = ""

    

    store.state.settings.savingModus = false

    window.addEventListener('keydown', handleKeyPress);
    if (vCardContainer.value?.$el) {
      vCardContainer.value.$el.addEventListener('keydown', focusTrap);
    }
    
}   

const register = async () => {
  shutdown()
  let newCalendar = {
            color: '#6AD069', //calendarColor.value, 
            icon: 'ph-CalendarCheck', //icon.value,
            name: 'Privat',
            creatorUID: store.state.user.userID,
            creatorName: "Annucal",
            externalCalendarLink: '',
            description: '',
            calendarImage:  "",
            type: '1',
            visible: true,
            specialday:'',
            private:true,
            country: '',
            subdivision: '',
            subdivisionCode: '',
            loadedPublicHolidays: false,
            events:[]
        }

  store.state.settings.emailErrorMessage = ""
  store.state.settings.passwordErrorMessage = ""
  form.value.email = store.state.settings.logInEmail
  form.value.password = store.state.settings.logInPassword
  form.value.passwordConfirmation = store.state.settings.logInPasswordConfirmation
  form.value.username="-"

  if (!/.+@.+\..+/.test(store.state.settings.logInEmail)) {
        store.state.settings.emailErrorMessage = t("novalidemailaddress")
        store.state.settings.savingModus = false
        return
  }
  else if (form.value.password !== form.value.passwordConfirmation) {
    store.state.settings.passwordErrorMessage = t("passworddonotmatch")
    store.state.settings.savingModus = false
  } else {
    store.state.settings.emailErrorMessage = ""
      store.state.settings.passwordErrorMessage = ""
    store.state.settings.savingModus = true
      await store.dispatch('user/register', form.value)
      
  }
  if (isAuthenticated.value) {
      newCalendar.creatorUID= store.state.user.userID,
      await store.dispatch('user/createCalendarAfterRegister', newCalendar)
      
      store.state.settings.Authenticated = true;
        router.push({ path: '/' }).then(() => {
        router.go();
        });
    }else{
      store.state.settings.savingModus = false
    }
};

const login = async () => {
  shutdown()
    store.state.settings.emailErrorMessage = ""
    store.state.settings.passwordErrorMessage = ""

    if(store.state.settings.logInEmail == ""){
        store.state.settings.emailErrorMessage = t("emailaddressrequired")
        store.state.settings.savingModus = false
        return
    }
    else if (!/.+@.+\..+/.test(store.state.settings.logInEmail)) {
        store.state.settings.emailErrorMessage = t("novalidemailaddress")
        store.state.settings.savingModus = false
        return
    }
    else if(store.state.settings.logInPassword == ""){
        store.state.settings.passwordErrorMessage = t("passwordrequired")
        store.state.settings.savingModus = false
        return
    }

    store.state.settings.emailErrorMessage = ""
    store.state.settings.savingModus = true

    form.value.email = store.state.settings.logInEmail
    form.value.password = store.state.settings.logInPassword

    await store.dispatch('user/login', form.value);
    if (isAuthenticated.value) {
        router.push({ path: '/' }).then(() => {
        router.go();
        });
    }else{
      store.state.settings.savingModus = false
    }

};

const logout = () => {
  shutdown()
  store.dispatch('user/logout');
  store.state.settings.introPageToShow = 1;
  store.state.settings.startPageOpen = true;
  router.push({ path: '/' }).then(() => {
    router.go();
  });
};

/*
const resetVariables = () => {
  showForgotPassword.value = false;
};
*/

const openForgotPassword = () => {
  store.state.settings.showForgotPassword = true;
  store.state.settings.showRegister = false;
  store.state.settings.AnnucalVcardHeadline = t("resetpassword")
  store.state.settings.Log_Status = "resetPassword"
  store.state.settings.AnnucalSaveText = t("Reset now")
};

const openRegister = () => {
  store.state.settings.logInEmail=""
  store.state.settings.logInPassword=""
  store.state.settings.logInPasswordConfirmation=""
  store.state.settings.AnnucalVcardHeadline = "Registrieren"
  store.state.settings.AnnucalSaveText = t("register")
  store.state.settings.Log_Status = "register"
  store.state.settings.showRegister = true
  store.state.settings.showForgotPassword = false
  
};



const openLogin = () => {
  store.state.settings.logInEmail=""
  store.state.settings.logInPassword=""
  store.state.settings.logInPasswordConfirmation=""
  store.state.settings.AnnucalVcardHeadline = "Anmelden"
  store.state.settings.AnnucalSaveText = t("login")
  store.state.settings.Log_Status = "login"
  store.state.settings.showRegister = false
  store.state.settings.showForgotPassword = false
};


const resetPassword = () => {
  if(store.state.settings.resetPasswordEmail == ""){
        store.state.settings.emailErrorMessage = t("emailaddressrequired")
        store.state.settings.savingModus = false
        return
  }else if (!/.+@.+\..+/.test(store.state.settings.resetPasswordEmail)) {
      store.state.settings.emailErrorMessage = t("novalidemailaddress")
      store.state.settings.savingModus = false
      return
  }
  error.value = null;
  message.value = null;
  emailSending.value = true;

  sendPasswordResetEmail(getAuth(), store.state.settings.resetPasswordEmail)
    .then(() => {
      emailSending.value = false;
      //console.log('Eine Email zum zurück setzen Deines Passworts wurde versendet');
      store.state.settings.Log_Status = ""
      
      store.state.settings.resetPasswordSuccess = true
      
    })
    .catch((error) => {
      emailSending.value = false;
      if (error.message.includes('invalid-email')) {
        console.log('Die Emailadresse scheint nicht korrekt zu sein');
      } else {
        console.log(error.message);
      }
    });
};





</script>

  
<style scoped>

.text-grid{
    padding-bottom:16px;
    float: right;
    margin-left: auto;
    display: inline-grid;
    
    grid-template-columns: 1fr 1fr;
    
}

.text-grid-left{
    
    text-align: right;
    
}

.text-grid-right{
    
    box-sizing: border-box;
    overflow: hidden; 
    transition: transform 0.2s ease-in-out; 
    width: 100%; 
    text-align: right; 
    
}

.text-grid-right:hover{
    transform: scale(1.02); 


    cursor: pointer;
}


.link-text{

transition: transform 0.2s ease-in-out;
}

.link-text:hover{

color: #191816;
cursor: pointer;
}


.info-text{
  font-size: 16px;
  font-weight: 300;
  color: #615E59;
  letter-spacing: -0.8px;
  text-align: left;
  margin-right: 2px;
  user-select: none;
}

.user-email-text{
  font-size: 16px;
  font-weight: 600;
  color: #615E59;
  letter-spacing: -0.8px;
  text-align: left;
  margin-right: 2px;
  user-select: none;
}

</style>
  
