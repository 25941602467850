<template>
    <div>

    </div>
       {{ $t( props.item.name)}}  
</template>

<script setup>

import { defineProps } from 'vue'

const props = defineProps({   
    item:Object
}); 

</script>

<style scoped>

.day-grid{
    
}

</style>
