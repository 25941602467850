
//import days2024 from "../../assets/Calendar2024.json"
import days2025 from "../../assets/Calendar2025.json"
import days2026 from "../../assets/Calendar2026.json"


var days = []

export default {

    state(){
        return{
            items: [],
            selectedYear: 2025,
            dayDictionary: {}
        }
    },

    methods:{
        changeyear(){
        }
    },

    actions:{
        getCalender({commit}){
            commit("setCalender", days);
        },

       
    },
    
    mutations:{

        setYear(state,year){
            state.selectedYear = year
        },

        setCalender(state, days){
            
            state.items= []

            days = days2025
            
            for (let x = 0; x < days.monthitems.length; x++) { 
                let dayarray = []
                for(let y = 0; y < 31; y++ ){
                    dayarray.push(days.monthitems[x].Dayitems[y]);
                    state.dayDictionary[days.monthitems[x].Dayitems[y].id]= {x,y};
                }
                state.items.push(dayarray);  
            } 

            days = days2026
            let x = 0
            
            for (let x1 = 0; x1 < days.monthitems.length; x1++) { 
                let dayarray = []
                x = x1 + 12
                for(let y = 0; y < 31; y++ ){
                    dayarray.push(days.monthitems[x1].Dayitems[y]);
                    state.dayDictionary[days.monthitems[x1].Dayitems[y].id]= {x,y};
                }
                state.items.push(dayarray); 
            } 
           
        },
        
    }
}



      