

import {db} from "../../db";
import { getDocs, collection, addDoc, doc, deleteDoc, updateDoc, Timestamp } from "firebase/firestore";

import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import  { ref,uploadBytes, getStorage, getDownloadURL } from "firebase/storage"


export default {

    actions:{

        async getmyCalendarsFromDB({commit}){
            const store = useStore();
            const snapshot = await getDocs(collection(db, "users", store.state.user.userID, "myCalendars"));
            
            const storedmycalendars = snapshot.docs.map(doc => {
                const data =  doc.data();
                const id = doc.id;
                return {id, ...data};
            })
            commit("setStoredMyCalendars",storedmycalendars);
            
        },

        async deleteCalendarFromMyCalendars(commit, data){
            
            await deleteDoc(doc(db, "users", data.creatorUID, "myCalendars", data.docId ));
            
        },

        async deleteCalendarFromCalendars(commit, data){
            
            await deleteDoc(doc(db,"calendars", data.calendarId))
            
        },

      

        async createCalendar(commit, data) {  

            let newCalendarData = {
                imageURL: data.icon, 
                MyCalendarsdocId: '',
                calendarId: '',
            }
            
            if(data.calendarImage != null && data.calendarImage != ''){
                
                const storage = getStorage()
                
                //--------------- UPLAD IMAGE TO FIREBASE STORAGE START -------------------------------

                const storageRef = ref(storage, 'users/' + data.creatorUID + '/calendarImages/' + uuidv4() + '_' + data.icon );
                await uploadBytes(storageRef,data.calendarImage).then(async ()=> {
                    await getDownloadURL(storageRef)
                    .then((url) => {
                        newCalendarData.imageURL = url
                    return url
                    })
                })

            //--------------- UPLAD IMAGE TO FIREBASE STORAGE END -------------------------------
            }
            
              

            //--------------- STORE NEW CALENDAR TO FIRESTORE START -------------------------------
             
            let newCalendar = {
                color: data.color, 
                icon: newCalendarData.imageURL,
                name: data.name,
                description: data.description,
                creatorName: data.creatorName,
                creatorUID: data.creatorUID,
                createdOn: Timestamp.now(),
                editedOn: Timestamp.now(),
                subscribers: 0,
                private: data.private,
                type: data.type,
                country: data.country,
                specialday:data.specialday,
                subdivision:data.subdivision,
                subdivisionCode: data.subdivisionCode,
                externalCalendarLink: data.externalCalendarLink,
                loadedPublicHolidays: false
            }

            await addDoc(collection(db, "calendars"), newCalendar)
            .then(
                 async (docRef) => {
                    await updateDoc(doc(db, 'calendars', docRef.id),{
                        calendarId : docRef.id,
                    });

                    newCalendarData.calendarId = docRef.id

                    const docRef2 = await addDoc(collection(collection(db, 'users'), data.creatorUID, 'myCalendars'), {
                                calendarId : docRef.id,
                                color: data.color, 
                                icon: newCalendarData.imageURL,
                                name: data.name,
                                visible: data.visible,
                                private: data.private,
                                description: data.description,
                                creatorName: "Annucal",
                                createdOn: Timestamp.now(),
                                editedOn: Timestamp.now(),
                                creatorUID: data.creatorUID,
                                subscribers: 0,
                                events:[],
                                type:data.type,
                                externalCalendarLink: data.externalCalendarLink,
                                specialday: data.specialday,
                                country: data.country,
                                subdivision: data.subdivision,
                                subdivisionCode: data.subdivisionCode,
                                loadedPublicHolidays: data.loadedPublicHolidays
                            });
                            newCalendarData.MyCalendarsdocId = docRef2.id

                            
                        }).
                        catch(function(error) {
                            console.error("Error adding document: ", error);
                        })

                      
                
                return newCalendarData
        },

        //--------------- STORE NEW CALENDAR TO FIRESTORE CALENDARS END -------------------------------

        async updateCalendar(commit,data){
            
            let newCalendar = {
                color: data.color, 
                name: data.name,
                description: data.description,
                icon: data.icon, 
                editedOn: Timestamp.now(),
                private: data.private,
                specialday: data.specialday,
                country: data.country,
                subdivision: data.subdivision,
                subdivisionCode: data.subdivisionCode,
                externalCalendarLink: data.externalCalendarLink,
                type: data.type,
     
            }
           await updateDoc(doc(db, "users", data.userId, "myCalendars", data.docId), newCalendar);
           await updateDoc(doc(db, "calendars", data.calendarId), newCalendar);
          
        },

        async getmydaysWithEventsFromDB({commit},{events, myCalendars}){
            for (let calenderIndex = 0; calenderIndex < myCalendars.length; calenderIndex++) {
                myCalendars[calenderIndex].events = []
                for (let eventIndex = 0; eventIndex < events.length; eventIndex++){
                    if(myCalendars[calenderIndex].calendarId == events[eventIndex].calendarId){
                        commit("addEventIndexToCalender", {myCalendars,calenderIndex, eventIndex}) 
                    }
                } 
            }
        },
    },

    mutations:{
        setStoredMyCalendars(state, storedmycalendars){
            
            state.items = []

            for (let index = 0; index < storedmycalendars.length; ++index){
                let newCalendar = {
                    docId: storedmycalendars[index].id,
                    calendarId: storedmycalendars[index].calendarId,
                    name: storedmycalendars[index].name,
                    description: storedmycalendars[index].description,
                    icon: storedmycalendars[index].icon,
                    color: storedmycalendars[index].color,
                    visible: storedmycalendars[index].visible,
                    creatorName: storedmycalendars[index].creatorName,
                    createdOn: storedmycalendars[index].createdOn,
                    editedOn: storedmycalendars[index].editedOn,
                    subscribers: storedmycalendars[index].subscribers,
                    menuopen: false,
                    fromHour: storedmycalendars[index].fromHour,
                    fromMinute: storedmycalendars[index].fromMinute,
                    toHour: storedmycalendars[index].toHour,
                    toMinute: storedmycalendars[index].toMinute,
                    private: storedmycalendars[index].private,
                    creatorUID: storedmycalendars[index].creatorUID,
                    type: storedmycalendars[index].type,
                    specialday: storedmycalendars[index].specialday,
                    country: storedmycalendars[index].country,
                    subdivision: storedmycalendars[index].subdivision,
                    subdivisionCode: storedmycalendars[index].subdivisionCode,
                    events: [],
                    externalCalendarLink: storedmycalendars[index].externalCalendarLink,
                    loadedPublicHolidays: storedmycalendars[index].loadedPublicHolidays
                  }
                  state.items.push(newCalendar)
                  
            }
        },

        addEventIndexToCalender(state,{myCalendars, calenderIndex, eventIndex}){
            //myCalendars[calenderIndex].events = []
            myCalendars[calenderIndex].events.push(eventIndex)
        },

    }
}
  
  