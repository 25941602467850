<template>
    <div :class="$store.state.settings.currentTheme + '-theme'">
  
      <v-card 
        style="
          background-color: #F6F2EF;
          border-radius: 8px;
          box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
          padding-left: 8px;
          padding-right: 8px;
          width: 280px;
          height: 32px;
          margin-top: -5px;
          margin-left: 8px;
        "
      >
      <v-slider
      thumb-size="12"
      tile="false"
      color="black"
      width="280"
      track-size="2"
      densitiy="compact"
      min="4.13"
      max="26"
      v-model="this.$store.state.settings.columnWidth"

    ></v-slider>
      </v-card>
    </div>
  </template>
  
  <script setup>
  import { onMounted } from 'vue';
  //import {useStore} from "vuex";

  //const store = useStore();
      //const { state } = store;
 // const screenWidth = ref(14)
  
  onMounted(() => {
    //store.state.settings.Log_Status = ""
  });
  

  //const emits = defineEmits(['closeCalendarMenu'])

  </script>
  
    
    <style scoped>
  
  
  
    .grid-container {
      display: grid;
      grid-template-columns: 130px auto;
      background-color: #ffffff;
      padding: 16px; /* Gleichmäßiges Padding */
    }
  
    .grid-item-links-active {
      font-size: 14px;
      text-align: left;
      font-weight: 300;
      letter-spacing: -0.6px;
      user-select: none;
    }
    
    .grid-item-links-inactive {
      font-size: 14px;
      text-align: left;
      font-weight: 300;
      letter-spacing: -0.6px;
      color: #85827C;
      user-select: none;
    }
    
    .grid-item-rechts {}
    
    .plus-button-active {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px; /* Gleiche Größe wie Checkbox */
      height: 18px; /* Gleiche Größe wie Checkbox */
      background-color: #e7e5df;
      border: 1px solid #e7e5df;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;
      margin-top: 2px;
      cursor: pointer;
    }
  
    .plus-button-inactive {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px; /* Gleiche Größe wie Checkbox */
      height: 18px; /* Gleiche Größe wie Checkbox */
      background-color: #f1f1f1;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;
      margin-top: 2px;
      
    }
    
    .plus-icon {
      color: #191816;
      font-size: 14px;
      font-weight: bold;
      transition: color 0.3s ease;
    }
    
    .plus-button-active:hover {
      background-color: #191816;
    }
    
    .plus-button-active:hover .plus-icon {
      color: white;
    }
  
  
  .row1 {
    display: grid; /* Jede Row bleibt ein Grid */
    grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
    align-items: center; /* Zentrierung vertikal */
    margin-bottom: 2px;
    padding:8px;
    background-color: white; /* Standardhintergrundfarbe */
    border-radius: 4px; /* Optional: Abgerundete Ecken */
  }
  
  .row2 {
    display: grid; /* Jede Row bleibt ein Grid */
    grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
    align-items: center; /* Zentrierung vertikal */
    background-color: white; /* Standardhintergrundfarbe */
    padding:8px;
    border-radius: 4px; /* Optional: Abgerundete Ecken */
  }
  
  .row1:hover,
  .row2:hover {
    cursor: pointer; /* Zeigt den Zeiger an */
    background-color: #e6e5e55a; /* Hover-Hintergrundfarbe */
  }
    
    .left-icon {
      width: 20px;
      height: 20px;
    }
    
    .left-icon-back {
      
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .right-icon-back {
      display: flex;
      justify-self: end; /* Horizontal nach rechts ausgerichtet */
      align-items: center;
    }
    
    .right-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #E7E5DF;
      border-radius: 4px; /* Runde Form */
      width: 20px; /* Größe des grauen Quadrats */
      height: 20px;
      margin-right: 4px;
    }
    
    .right-icon {
      width: 16px;
      height: 16px;
    }
    
    .center-column {
      text-align: left;
    }
    </style>
    