<template>
   <div :class="$store.state.settings.currentTheme + '-theme'">
  <v-card
  class="mx-auto"
    :width=props.VcardWitdh
    style="
        border-radius: 8px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
    ">
    
    <div 
      class="main-grid-container"
      :style="{ 
          paddingBottom: props.padding_botton + 'px',
        }"
      >

      <!-------------------------------------------------------------------------------------------->
      <!--------------------------------  Headline with Calendarname-------------------------------->
      <!-------------------------------------------------------------------------------------------->
      <div class="headline-grid-container" style="grid-row: 1;" v-if="showCalendarName" 
      :style="{ gridTemplateColumns: gridTemplateColumns }">

          
          <!---------------------------------  Calendar color ------------------------------------------>
          
            <div :style="firstColumnStyle">
              <div 
                  class="circle"
                  v-bind:style="{ 
                      backgroundColor: props.calendarColor, 
                  }"  
              >
              </div>
            </div>
          
          <!----------------------------------  Calendar name ------------------------------------------>
          <div class="headline-calendar-name annucal-text-18px" >
              <div>{{getHeadline()}}</div>
          </div>
          <!--------------------------------------  Close  --------------------------------------------->
          <div class="third-column" 
              style="
                  text-align: right; 
                  color: #656565;
                  margin-right: -13px;
                  margin-top:-8px;
              ">
           
              <v-btn tabindex="-1"
                color="#656565"  
                class="text-none"
                density="default"
                variant="text"  
                @click="closeMenu()"
              > 
              <div  class="button-text annucal-default-text-style"    style="margin-right: -4px;">
                   {{ $t('closewithx')}}
                </div> 
               
                <template v-slot:append>
                  <div 
      style="
        display: flex; 
        align-items: center; 
        color: #191816;
      "
    >
      <PhX :size="16" />
    </div>
                </template>
              </v-btn> 
              
        
          </div>
      </div>

      <!-------------------------------------------------------------------------------------------->
      <!------------------------------  Middle Slot for extern Content------------------------------>
      <!-------------------------------------------------------------------------------------------->
      
      <div style="grid-row: 2;" >
          <slot name="middle" />
      </div>

      <!-------------------------------------------------------------------------------------------->
      <!------------------------------  V-Card-Actions with Cancel, Save ... ------------------------>
      <!-------------------------------------------------------------------------------------------->
      
        <v-card-actions v-if="showvCardActions == true">

          <v-spacer></v-spacer>

          <!------------------------------------ Cancel Button ------------------------------------------>

          <div v-if="store.state.settings.resetPasswordSuccess == false"
              style="
              margin-bottom: -24px
              ">
                <v-btn 
            color="#615E59"  
            class="text-none button-text annucal-default-text-style" 
            density="default"
            variant="text"  
            @click="closeMenu()">  
            {{  $t("cancel")}}
          </v-btn> 
              </div>

          <!------------------------------------ Close Button ------------------------------------------>

          <div v-if="store.state.settings.resetPasswordSuccess == true"
              style="margin-right: -16px;
              margin-bottom: -24px
              ">
                <v-btn 
            color="#615E59"  
            class="text-none button-text annucal-default-text-style" 
            density="default"
            variant="text"  
            @click="closeMenu()">  
            {{  $t("close")}}
          </v-btn> 
              </div>
        

    <!------------------------------------ Delete Button ------------------------------------------>
          <div style="
          padding-left: 38px;
          padding-right: 28px;
          margin-bottom: -24px
          " v-if="showDeletingCircleDeleteEvent">
          <v-progress-circular class="circular-progress-trash" 
            indeterminate :size="20" 
            :width="2"
            color="#85827C"
            />
          </div>

          <div style="
              margin-right: 12px;
              margin-bottom: -24px
              ">
            <v-btn v-if="props.showDeleteButton  && !showDeletingCircleDeleteEvent && mycalendars.length > 1"
              color="#E30303"  
              class="text-none button-text annucal-default-text-style" 
              density="default"
              variant="text"  
              @click="deleteElement()"> 
              <template v-slot:prepend>
                <PhTrash class="delete-icon-size " />
              </template>
              
              {{ $t(store.state.settings.AnnucalDeleteText) }}
            
            </v-btn> 
          </div>
          

          <!------------------------------------ Save Button ------------------------------------------>

          <div  
                style="margin-right: 8px;
                        margin-bottom: -24px">
            <v-btn v-if="props.prop_mode == 'new'  || this.$store.state.settings.Log_Status == 'login'"
              density="default"  
              class="text-none button-text-save annucal-default-text-style"    
              variant="flat" 
              type="submit"
              :loading="this.$store.state.settings.savingModus"
              @click="savenew">
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  color="white"
                  size="24"
                  width="2"
                ></v-progress-circular>
              </template>
              {{ $t( this.$store.state.settings.AnnucalSaveText ) }}
            </v-btn> 
          </div>
      

          <div style="
          margin-bottom: -24px">
            <v-btn v-if=" this.$store.state.settings.Log_Status == 'register'"
              density="default"  
              class="text-none button-text-save annucal-default-text-style"    
              variant="flat" 
              type="submit"
              :loading="this.$store.state.settings.savingModus"
              @click="register">
              {{ $t( this.$store.state.settings.AnnucalSaveText ) }}
            </v-btn>  
          </div>

          <div style="
          margin-bottom: -24px">
            <v-btn v-if=" this.$store.state.settings.Log_Status == 'resetPassword'"
              density="default"  
              class="text-none button-text-save annucal-default-text-style"    
              variant="flat" 
              type="submit"
              :loading="this.$store.state.settings.savingModus"
              @click="resetPassword">
              {{ $t( this.$store.state.settings.AnnucalSaveText ) }}
            </v-btn>  
          </div>

          <div style="margin-right: -8px;
          margin-bottom: -24px">
            <v-btn v-if=" this.$store.state.settings.Log_Status == 'settings'"
              density="default"  
              class="text-none button-text-save annucal-default-text-style"    
              variant="flat" 
              type="submit"
              :loading="this.$store.state.settings.savingModus"
              @click="savesettings">
              {{ $t( this.$store.state.settings.AnnucalSaveText ) }}
            </v-btn>  
          </div>
        
       
          <div style="margin-right: -8px;
          margin-bottom: -24px">
            <v-btn v-if="props.prop_mode == 'edit' || this.$store.state.settings.Log_Status == 'logout'"
              density="default"  
              class="text-none button-text-save annucal-default-text-style"    
              variant="flat" 
              type="submit"
              :loading="this.$store.state.settings.savingModus"
              @click="saveedit">
              {{ $t( this.$store.state.settings.AnnucalSaveText ) }}
            </v-btn>  
          </div>
         
         
       
      </v-card-actions>

    </div>
  
</v-card>

</div>
  

</template> 

<script setup>

import { defineProps, defineEmits, ref, computed, onBeforeMount, onBeforeUnmount } from 'vue';
import {useStore} from "vuex";
    import { useI18n } from 'vue-i18n'

    const { t } = useI18n()

const props = defineProps({     
  item: Object,
  showvCardActions: {
    type: Boolean,
    default: true
  },
  isMenu:{
    type: Boolean,
    default: false
  },
  showCalendarName: {
    type: Boolean,
    default: false
  },
  mode: {
    type: String,
    required: true
  },
  calendarId: {
    type: String,
    required: true
  },
  calendarColor: {
    type: String
  },
  calendarName: {
    type: String
  },
  VcardWitdh: {
    type: Number,
    required : true
  },
  VcardMarginTop: {
    type: Number,
    required : true
  },
  VcardMarginLeft: {
    type: Number,
    required : true
  },
  saveText: {
    type: String,
    default: "save"
  },
  showDeleteButton: {
    type: Boolean,
    default: false
  },
  deleteText: {
    type: String,
    default: "Löschen"
  },
  prop_mode: {
    type: String,
    required : true
  },
  first_column_width: {
    type: Number,
    required : true
  },
  padding_botton: {
    type: Number,
    required : true
  },
}); 



onBeforeMount(() => {
  columnWidth.value = props.first_column_width
  Headline.value =  state.settings.AnnucalVcardHeadline
  window.addEventListener('keydown', handleKeyPress);

});

onBeforeUnmount(() => {
        window.removeEventListener('keydown', handleKeyPress);
});

const handleKeyPress = (event) => {
    if (event.key === 'Esc') {
       console.log("Escape pressend")
    }
};

const showDeletingCircleDeleteEvent = ref(false)
const showSavingCircleDeleteEvent = ref(false)
const isSaving = ref(false)
const Headline = ref("")

const emit = defineEmits(['close-menu', 'savenew', 'deleteElement', 'register', 'savesettings', 'resetPassword'])

const store = useStore();
    
    const { state } = store;

const showCircleProgress = ref(false);

const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })


function closeMenu(){
  emit('close-menu')
  state.settings.showRegisterDialog = false
  state.settings.showLoginDialog = false
}

function savesettings(){
  emit('savesettings')
}

function savenew(){
  showCircleProgress.value = true
  if(showDeletingCircleDeleteEvent.value == true){
    return
  }
  //showSavingCircleDeleteEvent.value = true
  showCircleProgress.value = true
  isSaving.value = true
  emit('savenew')
}

function saveedit(){
  if(showDeletingCircleDeleteEvent.value == true){
    return
  }
  showSavingCircleDeleteEvent.value = true
  emit('saveEdit')
}

function resetPassword(){
  if(showDeletingCircleDeleteEvent.value == true){
    return
  }
  emit('resetPassword')
}

function register(){
  if(showDeletingCircleDeleteEvent.value == true){
    return
  }
  emit('register')
}

  
function getHeadline(){
  if(props.isMenu == true){
    return t("menu")
  }else{
    return state.settings.AnnucalVcardHeadline
  }
}


function deleteElement(){
if(showSavingCircleDeleteEvent.value == true){
  return
}
showDeletingCircleDeleteEvent.value = true
  emit('deleteElement')
}

// Reactive Variable für die Breite der ersten Spalte
const columnWidth = ref(14);

// Computed Property, um den Stil der ersten Spalte zu berechnen
const firstColumnStyle = computed(() => {
  return {
    width: columnWidth.value > 0 ? columnWidth.value + 'px' : '0',
    display: columnWidth.value > 0 ? 'block' : 'none',
  };
});

// Computed Property für die Grid-Template-Columns
const gridTemplateColumns = computed(() => {
  return columnWidth.value > 0
    ? `${columnWidth.value}px 1fr 1fr`
    : `1fr 1fr`; // Passt die Breiten der verbleibenden Spalten an, wenn die erste Spalte 0 ist
});

</script>

<style scoped>

.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;

}

.main-grid-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 32px auto   ;
  gap: 1px;
  background-color: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 16px;
  width: 100%;
}

.third-column {
  display: flex;
  align-items: center; /* Vertikale Zentrierung */
  justify-content: flex-end; /* Horizontale Ausrichtung nach rechts */
}

.headline-grid-container {
  display: grid;
  grid-template-columns: auto 1fr 1fr; /* 'auto' für die erste Spalte, die von der Breite her variabel ist */
  gap: 10px;
  width: 100%;
}

.headline-calendar-name{
  display: flex;
  align-items: top;
  margin-top:1.5px;
}

.button-text{
  text-align: right;
  margin-right: 2px;
}

.button-text:hover{
  background-color: #f0e8e349;
}

.action{
  margin-top: 20px;
}

.button-text-save:hover {
  background-color: #212121; /* Set button background to black on hover */
  color:#F6F2EF;
}

.button-text-save{
  background-color: #F6F2EF;
  padding-right: 5px;
  padding-left: 5px;
  transition: background-color 0.3s; 
}

.delete-icon-size {
  font-size: 20px; /* Adjust the size of the icon here */
  margin-right: -6px; /* Adjust the space between icon and text */
}

</style>



