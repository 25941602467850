<template>
  <div class="custom-checkbox"
      v-bind="attrs"
      v-on="on">
      <input
          type="checkbox"
          :id="id"
          v-model="checked"
          @change="emitChange"
      />
      <label :for="id" class="checkbox-label" :style="labelStyles">
          <PhCheck v-if="checked" class="check-icon" />
      </label>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  value: {
    type: Boolean,
    default: false
  },
  checkboxBackgroundColor: {
    type: String,
    default: '#DAFDD8' // Default value if not provided
  }
});

// Define emits
const emit = defineEmits(['update:value', 'state-change']);

// Reactive state
const checked = ref(props.value);

// Method to emit changes
function emitChange() {
  emit('update:value', checked.value);
  emit('state-change', props.id, checked.value);
}

function getSecondBackgroundColor(){
      switch(props.checkboxBackgroundColor) {
      case '#6AD069':
        return '#7FF47E'
      case '#4BA0C4':
        return '#54B6DF'
      case '#5C70D7':
        return '#6278E9'
      case '#9D41D5':
        return '#B857F3'
      case '#D272DA':
        return '#E67BEF'
      case '#A39F96':
        return '#C6C3BB'
      default:
        return '#7FF47E'

  }
    }

    function getThirdBackgroundColor(){
      switch(props.checkboxBackgroundColor) {
      case '#6AD069':
        return '#DAFDD8'
      case '#4BA0C4':
        return '#C4EDFF'
      case '#5C70D7':
        return '#D1D8FF'
      case '#9D41D5':
        return '#E5BBFF'
      case '#D272DA':
        return '#FABDFF'
      case '#A39F96':
        return '#F6F2EF'
      default:
        return '#DAFDD8'
  }}


const labelStyles = computed(() => ({
  '--checkbox-background-color': getThirdBackgroundColor(),
  '--checkbox-border-color': getSecondBackgroundColor(),
  '--hover-border-color': props.checkboxBackgroundColor,
  '--checked-background-color': getSecondBackgroundColor(),
  '--checked-hover-background-color': props.checkboxBackgroundColor
}));
</script>

<style scoped>
.custom-checkbox {
  display: inline-block;
  position: relative;
}

input[type='checkbox'] {
  display: none;
}

.checkbox-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--checkbox-border-color);
  background-color: var(--checkbox-background-color);
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.check-icon {
  color: #191816;
  font-size: 12px;
  position: absolute;
}

input[type='checkbox']:checked + .checkbox-label {
  background-color: var(--checked-background-color);
  border-color: transparent;
}

.checkbox-label:hover {
  border-color: var(--hover-border-color);
  cursor: pointer;
}

input[type='checkbox']:checked + .checkbox-label:hover {
  background-color: var(--checked-hover-background-color);
  border-color: transparent;
}
</style>
