import {db} from "../../db";
import { getDocs, collection, addDoc, doc, deleteDoc, updateDoc, Timestamp } from "firebase/firestore";
import format from 'date-fns/format' 
import timezones from "../../assets/timezones.json"
import { DateTime } from 'luxon'
import axios from 'axios';

var publicCalendarsLoaded = []

export default {

    actions:{

        async initEventItems({commit}){
            commit("initItems");
        },

        

        async getmyEventsFromDB({commit},userId){
          
            commit("initItems");
            
            const theCalendars = await getDocs(collection(db, "users", userId, "myCalendars"));
            const myCalendars = theCalendars.docs.map(doc => {
                const data =  doc.data();
                const id = doc.id;
                return {id, ...data};
            });
            for (let i = 0; i < myCalendars.length; i++) {
                var snapshot = await getDocs(collection(db, "calendars", myCalendars[i].calendarId, "events"));
                var storedevents = snapshot.docs.map(doc => {
                    const data =  doc.data();
                    const id = doc.id;
                    return {id, ...data};
                })
                commit("addStoredEvents",storedevents);
            }  
        },

        async refreshIndexList({commit}){
            commit("createIndexList");
        },

        async deleteEventFromCalendar(commit, data){
            await deleteDoc(doc(db, "calendars", data.calendarId, "events", data.eventidDB));
        },


        async editPublicHoliday({commit}, userId){
            commit("getPublicHolidaysFromApi",userId)
        },
        
        async getPublicHolidaysFromApi({commit}, { userId, localCalendars, language }){
            try{
                var myCalendars = []
                if(userId != null){
                    const theCalendars = await getDocs(collection(db, "users", userId, "myCalendars"));
                    myCalendars = theCalendars.docs.map(doc => {
                        const data =  doc.data();
                        const id = doc.id;
                        return {id, ...data};
                    });
                }else{
                    for(let x = 0; x < localCalendars.length; ++x){
                        myCalendars.push(localCalendars[x])
                    }
                    //myCalendars = localCalendars
                }
         
                var events = []
                for (let mc = 0; mc < myCalendars.length; mc++){
                    //----------------------- get public holidays -----------------------------------
                    if(myCalendars[mc].type == '2'){
                        
                        if(!publicCalendarsLoaded.includes(myCalendars[mc].calendarId)){
                            publicCalendarsLoaded.push(myCalendars[mc].calendarId)
                            const response = await axios.get(
                            //`https://api.xmltime.com/holidays?version=3&accesskey=uvFUBJyTRG&secretkey=VOGwbcZMT55SNRJrCN5K&country=${myCalendars[mc].subdivisionCode.slice(0, 2)}&year=2024&lang=de&countrydefault=1&types=federal,local&out=json&prettyprint=1`
                            //https://dev.timeanddate.com/account/
                            `https://calendarific.com/api/v2/holidays?&api_key=aQv7uWyIR3EvKLafkln343ZBufWpArKh&country=${myCalendars[mc].subdivisionCode.slice(0, 2)}&location=${myCalendars[mc].subdivisionCode}&year=2024&type=national,local&language=${language.value == undefined? language : language.value}`
                            );
                            if (response.data) {
                                if(response.data != undefined){
                                    events = []
                                    for (let i = 0; i < response.data.response.holidays.length; i++){
                                        let newEvent = {
                                            calendarId: myCalendars[mc].calendarId,
                                            id: 'datefrom', //#####
                                            eventidDB: '0',
                                            userid: userId,
                                            color: myCalendars[mc].color, 
                                            title: response.data.response.holidays[i].name,
                                            datefrom: response.data.response.holidays[i].date.iso,
                                            dateto: response.data.response.holidays[i].date.iso, //#####
                                            datefromJson: new Date(response.data.response.holidays[i].date.iso),// getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", 
                                            datetoJson: new Date(response.data.response.holidays[i].date.iso),// getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", 
                                            icon: myCalendars[mc].icon,
                                            fromHour: '00',
                                            fromMinute: '01',
                                            toHour: '23',
                                            toMinute: '59',
                                            isFullDay: true,
                                            menuopen: false, 
                                            eventInfoBoxOpen: false, 
                                            creatorUID: userId,
                                            calendarName: myCalendars[mc].name,
                                            additionalInfos: myCalendars[mc].country + ", " + myCalendars[mc].subdivision,//response.data.holidays[i].oneliner[0].text == undefined? "":response.data.holidays[i].oneliner[0].text,
                                            draggable: false,
                                            cursor: "default",
                                            rRules: "ONCE",
                                            location: "",
                                            removedDays: 0,
                                            publicHoliday:true,
                                            type:'2'
                                        }
                                        //this.state.events.items.push(newEvent)
                                        //this.state.items.push(newEvent)
                                        events.push(newEvent)
                                    }
                                    commit("addStoredEvents",events);
                                    commit("createIndexList");
                                    
                                }else{
                                    console.log("Error - getHolidaysFromTimeAndDate")
                                }
                            } 

                            
                            const response2 = await axios.get(
                                //`https://api.xmltime.com/holidays?version=3&accesskey=uvFUBJyTRG&secretkey=VOGwbcZMT55SNRJrCN5K&country=${myCalendars[mc].subdivisionCode.slice(0, 2)}&year=2024&lang=de&countrydefault=1&types=federal,local&out=json&prettyprint=1`
                                //https://dev.timeanddate.com/account/
                                `https://calendarific.com/api/v2/holidays?&api_key=aQv7uWyIR3EvKLafkln343ZBufWpArKh&country=${myCalendars[mc].subdivisionCode.slice(0, 2)}&location=${myCalendars[mc].subdivisionCode}&year=2025&type=national,local&language=${language.value == undefined? language : language.value}`
                                );
                                if (response2.data) {
                                    if(response2.data != undefined){
                                        events = []
                                        for (let i = 0; i < response2.data.response.holidays.length; i++){
                                            let newEvent = {
                                                calendarId: myCalendars[mc].calendarId,
                                                id: 'datefrom', //#####
                                                eventidDB: '0',
                                                userid: userId,
                                                color: myCalendars[mc].color, 
                                                title: response2.data.response.holidays[i].name,
                                                datefrom: response2.data.response.holidays[i].date.iso,
                                                dateto: response2.data.response.holidays[i].date.iso, //#####
                                                datefromJson: new Date(response2.data.response.holidays[i].date.iso),// getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", 
                                                datetoJson: new Date(response2.data.response.holidays[i].date.iso),// getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", 
                                                icon: myCalendars[mc].icon,
                                                fromHour: '00',
                                                fromMinute: '01',
                                                toHour: '23',
                                                toMinute: '59',
                                                isFullDay: true,
                                                menuopen: false, 
                                                eventInfoBoxOpen: false, 
                                                creatorUID: userId,
                                                calendarName: myCalendars[mc].name,
                                                additionalInfos: myCalendars[mc].country + ", " + myCalendars[mc].subdivision,//response.data.holidays[i].oneliner[0].text == undefined? "":response.data.holidays[i].oneliner[0].text,
                                                draggable: false,
                                                cursor: "default",
                                                rRules: "ONCE",
                                                location: "",
                                                removedDays: 0,
                                                publicHoliday:true,
                                                type:'2'
                                            }
                                            //this.state.events.items.push(newEvent)
                                            //this.state.items.push(newEvent)
                                            events.push(newEvent)
                                        }
                                        commit("addStoredEvents",events);
                                        commit("createIndexList");
                                        
                                    }else{
                                        console.log("Error - getHolidaysFromTimeAndDate")
                                    }
                                } 
                                
                        }
                    }else if(myCalendars[mc].type === '3'){
                        //----------------------- get school holidays -----------------------------------
                        if(!publicCalendarsLoaded.includes(myCalendars[mc].calendarId)){
                            publicCalendarsLoaded.push(myCalendars[mc].calendarId)
                            const response = await axios.get(
                              //  `https://openholidaysapi.org/SchoolHolidays?countryIsoCode=DE&languageIsoCode=EN&validFrom=2024-01-01&validTo=2024-12-31&subdivisionCode=DE-BAY`
                            `https://openholidaysapi.org/SchoolHolidays?countryIsoCode=${myCalendars[mc].subdivisionCode.slice(0, 2)}&EN&validFrom=2024-01-01&validTo=2025-12-31&subdivisionCode=${myCalendars[mc].subdivisionCode}`
                            //`https://openholidaysapi.org/SchoolHolidays?countryIsoCode=${language.value}&languageIsoCode=${language.value}&validFrom=2024-01-01&validTo=2025-12-31&subdivisionCode=${myCalendars[mc].subdivisionCode.slice(0, 2)}`
                            //https://openholidaysapi.org/swagger/index.html
                            );
                            if (response.data) {
                                if(response.data != undefined){
                                    events = []
                                    for (let i = 0; i < response.data.length; i++){

                                        //----Name of event in selected language Start ---
                                        var eventText = response.data[i].name[0].text //Fallback

                                        for (let r = 0; r < response.data[i].name.length; r++){
                                            if(response.data[i].name[r].language === language.value){
                                                eventText = response.data[i].name[r].text
                                            }
                                        }
                                        //----Name of event in selected language End ---

                                        let newEvent = {
                                            calendarId: myCalendars[mc].calendarId,
                                            id: 'datefrom', 
                                            eventidDB: response.data[i].id,
                                            userid: userId,
                                            color: myCalendars[mc].color, 
                                            title: eventText,
                                            datefrom: response.data[i].startDate,
                                            dateto: response.data[i].endDate, 
                                            datefromJson: new Date(response.data[i].startDate),// getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", 
                                            datetoJson: new Date(response.data[i].endDate),// getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", getMonthName(response.data.holidays[i].date.datetime.month) + " " + response.data.holidays[i].date.datetime.day + ", " + response.data.holidays[i].date.datetime.year + " 12:00:00 UTC", 
                                            icon: myCalendars[mc].icon,
                                            fromHour: '00',
                                            fromMinute: '01',
                                            toHour: '23',
                                            toMinute: '59',
                                            isFullDay: true,
                                            menuopen: false, 
                                            eventInfoBoxOpen: false, 
                                            creatorUID: userId,
                                            calendarName: myCalendars[mc].name,
                                            additionalInfos: myCalendars[mc].country + ", " + myCalendars[mc].subdivision,//response.data.holidays[i].oneliner[0].text == undefined? "":response.data.holidays[i].oneliner[0].text,
                                            draggable: false,
                                            cursor: "default",
                                            rRules: "ONCE",
                                            location: "",
                                            removedDays: 0,
                                            publicHoliday:true,
                                            type:'3'
                                        }
                                        //this.state.events.items.push(newEvent)
                                        //this.state.items.push(newEvent)
                                        events.push(newEvent)
                                    }
                                    commit("addStoredEvents",events);
                                    commit("createIndexList");
                                    
                                }else{
                                    console.log("Error - getHolidaysFromTimeAndDate")
                                }
                            } 
                        }
                    }
                }
            }
            catch(error){
                console.error('Error fetching holidays:', error);

                if (error.response && error.response.status === 401) {
                    console.error('Unauthorized: Check your API key or subscription.');
                    alert('Unauthorized: Your API key might be incorrect or inactive.');
                }

                this.holidays = [];  // Setze holidays-Liste auf leer im Fehlerfall

            }
            
        },
    

        createEventOnlyInBrowser(commit,data){
            
        const getMonthName = (month) =>{

                switch(month) {
                case 1:
                    return "January"
                case 2:
                    return "February"
                case 3:
                    return "March"
                case 4:
                    return "April"
                case 5:
                    return "May"
                case 6:
                    return "June"
                case 7:
                    return "July"
                case 8:
                    return "August"
                case 9:
                    return "Sepetember"
                case 10:
                    return "October"
                case 11:
                    return "November"
                case 12:
                    return "December"
                }
            }

            const getTimeZoneOffset = (timezone) =>{
                for (let t = 0; t < timezones.length; t++){
                    if(timezone == timezones[t].name){
                        return DateTime.local().offset/60 -  timezones[t].gmtOffset / 60/60
                    }
                }
                return 0
            }

            var counter = 0
            
            for (let i12 = 0; i12 < data.events.length; i12++){
                try{
                    counter = i12
                    var timeSetOffset = 0
                    
                    if(data.events[i12].dtstart.timezone != undefined){
                        //var newStartDate  =  DateTime.fromISO(data.events[i12].dtstart.value, { zone: data.events[i12].dtstart.timeZone }).toUTC()
                        //console.log("NewStartDate: " + new Date(newStartDate) + "-Offset: " + new Date(newStartDate).getTimezoneOffset() + " summary: " + data.events[i12].summary)
                        timeSetOffset = getTimeZoneOffset(data.events[i12].dtstart.timezone) 
                    }

                    if(data.events[i12].summary == "Annucal Jour Fixe"){
                        console.log("x")
                    }
                  
                    if(data.events[i12].status == "CONFIRMED"){
                     
                        var strYear_from = data.events[i12].dtstart.value.substr(0,4);
                        var strMonth_from = parseInt(data.events[i12].dtstart.value.substr(4,2),10) - 1;
                        var strDay_from = data.events[i12].dtstart.value.substr(6,2);
                        var strHour_from = parseInt(data.events[i12].dtstart.value.substr(9,2))  + timeSetOffset;
                        var strMin_from = data.events[i12].dtstart.value.substr(11,2);
                        var strSec_from = data.events[i12].dtstart.value.substr(13,2);
                        var oDate_start =  new Date(strYear_from,strMonth_from, strDay_from, strHour_from, strMin_from, strSec_from);
                        var oDatefromJson= getMonthName(strMonth_from) + " " + strDay_from + ", " + strYear_from + " 12:00:00 UTC" 
            
                        var strYear_to = data.events[i12].dtend.value.substr(0,4);
                        var strMonth_to = parseInt(data.events[i12].dtend.value.substr(4,2),10) - 1;
                        var strDay_to = data.events[i12].dtend.value.substr(6,2);
                        var strHour_to = parseInt(data.events[i12].dtend.value.substr(9,2))  + timeSetOffset;
                        var strMin_to = data.events[i12].dtend.value.substr(11,2);
                        var strSec_to = data.events[i12].dtend.value.substr(13,2);
                        var oDate_to =  new Date(strYear_to,strMonth_to, strDay_to, strHour_to, strMin_to, strSec_to);
                        var oDatetoJson= getMonthName(strMonth_to) + " " + strDay_to + ", " + strYear_to + " 12:00:00 UTC" 
    
                        let newEvent = { 
                            calendarId: data.calendarInfos.calendarInfos.calendarId, // OK
                            color: data.calendarInfos.calendarInfos.color, // OK
                            title: data.events[i12].summary, // OK
                            datefrom: format(oDate_start, 'yyyy-MM-dd') ,
                            dateto: format(oDate_to, 'yyyy-MM-dd') ,
                            datefromJson: oDatefromJson ,
                            datetoJson: oDatetoJson,
                            icon: data.calendarInfos.calendarInfos.icon, // OK
                            fromHour: strHour_from,
                            fromMinute: strMin_from,
                            toHour: strHour_to,
                            toMinute: strMin_to,
                            isFullDay: data.isFullDay != undefined? data.isFullDay : "",
                            creatorUID: "subscribed external calendar",
                            calendarName: data.calendarInfos.calendarInfos.name, // OK
                            additionalInfos: data.events[i12].description, // OK
                            createdOn: Timestamp.now(), // OK
                            editedOn: Timestamp.now(), // OK
                            rRules: data.events[i12].rrule,
                            location: (data.events[i12].location == undefined)? "" : data.events[i12].location,
                            removedDays: [],
                            type: data.events[i12].type
                            
                        }
                        this.state.items.push(newEvent)
                }
                
                }catch(e){
                    console.log("Error in createEventOnlyInBrowser: " + e + "Data: " + data + " Index: " + counter)
                }
            }
                 
        },

        removePublicCalendarFromList(commit, calendarId) {
            const index = publicCalendarsLoaded.indexOf(calendarId);
            if (index !== -1) {
                publicCalendarsLoaded.splice(index, 1);
            }
            
        },

        removeAllPuplicCalendarsFromList(commit, value){
            console.log(value)
            publicCalendarsLoaded = []
        },

        async createEvent(commit, data) {
            
            
            let newEvent = {
                calendarId: data.calendarId,
                color: data.color, 
                title: data.title,
                datefrom: data.datefrom,
                dateto: data.dateto,
                datefromJson: data.datefromJson ,
                datetoJson: data. datetoJson,
                icon: data.icon,
                fromHour: data.fromHour,
                fromMinute: data.fromMinute,
                toHour: data.toHour,
                toMinute: data.toMinute,
                isFullDay: data.isFullDay,
                creatorUID: data.creatorUID,
                calendarName: data.calendarName,
                additionalInfos: data.additionalInfos,
                createdOn: Timestamp.now(),
                editedOn: Timestamp.now(),
                rRules: data.rRules,
                location: (data == undefined)? "" : data.location,
                removedDays: [],
                type: data.type
                
            }

            await addDoc(collection(db, "calendars", data.calendarId, "events"), newEvent)
            .then(
                    async (docRef) => {
                        await updateDoc(doc(db, "calendars",data.calendarId, "events", docRef.id),{
                        docId : docRef.id,
                   })
                   let newEvent2 = {
                    calendarId: data.calendarId,
                    eventidDB: docRef.id,
                    color: data.color, 
                    title: data.title,
                    datefrom: data.datefrom,
                    dateto: data.dateto,
                    datefromJson: data.datefromJson ,
                    datetoJson: data. datetoJson,
                    icon: data.icon,
                    fromHour: data.fromHour,
                    fromMinute: data.fromMinute,
                    toHour: data.toHour,
                    toMinute: data.toMinute,
                    isFullDay: data.isFullDay,
                    creatorUID: data.creatorUID,
                    calendarName: data.calendarName,
                    additionalInfos: data.additionalInfos,
                    createdOn: Timestamp.now(),
                    editedOn: Timestamp.now(),
                    rRules: (data.rRules == undefined)? "ONCE" :  data.rRules,
                    location: (data.location == undefined)? "" : data.location,
                    removedDays: [],
                    type: data.type
                    
                }
                this.state.events.items.push(newEvent2)
                var x = this.state.events.items
                console.log(x)
                })

            
            
        },

        async updateStoredEventOnlyLocal({commit},data){
            commit("updateStoredEventLocal", data);
        },

      

        async updateStoredEvent({commit},data){

            await updateDoc(doc(db, "calendars", data.calendarId, "events", data.eventidDB),{
                title: data.title,
                datefrom: data.datefrom,
                dateto: data.dateto,
                datefromJson: data.datefromJson ,
                datetoJson: data. datetoJson,
                fromHour: data.fromHour,
                fromMinute: data.fromMinute,
                toHour: data.toHour,
                toMinute: data.toMinute,
                isFullDay: data.isFullDay,
                additionalInfos: data.additionalInfos,
                editedOn: Timestamp.now(), 
                rRules: data.rRules,
                location: (data.location == undefined)? "" : data.location,
                removedDays: []//data.removedDays
            })
       
           // Update local variables
           commit("updateStoredEventLocal", data);

        }
    },

    mutations:{

        initItems(state){
            state.items = [];
        },

        setStoredEvents(state, storedevents){

            state.items = []

            for (let index = 0; index < storedevents.length; ++index){
                let newEvent = {
                    id: storedevents[index].datefrom,
                    eventidDB: storedevents[index].id,
                    userid: storedevents[index].userid,
                    color: storedevents[index].color, 
                    title: storedevents[index].title,
                    datefrom: storedevents[index].datefrom,
                    dateto: storedevents[index].dateto,
                    datefromJson: storedevents[index].datefromJson,
                    datetoJson: storedevents[index].datetoJson,  
                    icon: storedevents[index].icon,
                    fromHour: storedevents[index].fromHour,
                    fromMinute: storedevents[index].fromMinute,
                    toHour: storedevents[index].toHour,
                    toMinute: storedevents[index].toMinute,
                    isFullDay: storedevents[index].isFullDay,
                    additionalInfos:  storedevents[index].additionalInfos,
                    rRules: storedevents[index].rRules,
                    location: (storedevents[index].location == undefined)? "" : storedevents[index].location,
                    removedDays: [], //storedevents[index].removedDays,
                    type: storedevents[index].type
                    
                    
                  }
                  state.items.push(newEvent)
            }
        },

        updateStoredEventLocal(state, data){

            for(let index = 0; index < state.items.length; index++){
                if(state.items[index].eventidDB == data.eventidDB){
                    state.items[index].title = data.title,
                    state.items[index].datefrom = data.datefrom,
                    state.items[index].dateto = data.dateto,
                    state.items[index].datefromJson = data.datefromJson,
                    state.items[index].datetoJson = data.datetoJson,  
                    state.items[index].fromHour = data.fromHour,
                    state.items[index].fromMinute = data.fromMinute,
                    state.items[index].toHour = data.toHour,
                    state.items[index].toMinute = data.toMinute,
                    state.items[index].isFullDay = data.isFullDay,
                    state.items[index].additionalInfos =  data.additionalInfos,
                    state.items[index].rRules =  data.rRules,
                    state.items[index].location= (data.location == undefined)? "" : data.location,
                    state.items[index].removedDays = [],//data.removedDays
                    state.items[index].type = data.type
                }
            }
            //createIndexList
            for (let index = 0; index < state.items.length; index++){
                state.items[index].index = index;
            }
        },

        addStoredEvents(state, storedevents){    
            for (let index = 0; index < storedevents.length; ++index){
                let newEvent = {
                    calendarId: storedevents[index].calendarId,
                    id: storedevents[index].datefrom,
                    eventidDB: storedevents[index].id,
                    userid: storedevents[index].creatorUID == null? "notSignedIn": storedevents[index].creatorUID,
                    color: storedevents[index].color, 
                    title: storedevents[index].title,
                    datefrom: storedevents[index].datefrom,
                    dateto: storedevents[index].dateto,
                    datefromJson: storedevents[index].datefromJson,
                    datetoJson: storedevents[index].datetoJson,
                    icon: storedevents[index].icon,
                    fromHour: storedevents[index].fromHour,
                    fromMinute: storedevents[index].fromMinute,
                    toHour: storedevents[index].toHour,
                    toMinute: storedevents[index].toMinute,
                    isFullDay: storedevents[index].isFullDay,
                    menuopen: false, 
                    eventInfoBoxOpen: false, 
                    creatorUID: storedevents[index].creatorUID === null? "notSignedIn": storedevents[index].creatorUID ,
                    calendarName: storedevents[index].calendarName,
                    additionalInfos: storedevents[index].additionalInfos,
                    draggable: false,
                    cursor: "default",
                    rRules: storedevents[index].rRules,
                    location: (storedevents[index].location == undefined)? "" : storedevents[index].location,
                    removedDays: [],//storedevents[index],
                    publicHoliday: storedevents[index].publicHoliday,
                    type: storedevents[index].type
                    
                  }
               
                  state.items.push(newEvent)
            }
        },

        
        createIndexList(state){
            
            for (let index = 0; index < state.items.length; index++){
                state.items[index].index = index;
            }
        }
    }
}
  
  


