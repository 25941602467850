<template>
  <div :class="this.$store.state.settings.currentTheme + '-theme'">
      <v-btn 
          ref="buttonRef" 
          color="#191816"  
          class="text-none annucal-default-text-style" 
          density="default"
          :variant="variant"  
      >  
          {{$t(props.linktext)}}
          
          <template v-slot:prepend>
              <component 
                  class="icon-style"
                  :size="20" 
                  :is="currentIcon"
              /> 
        </template>
      </v-btn> 
  </div>
  </template>
  
  <style scoped>

  .icon-style{
      color:var(--standard-text-color);
  }
  </style>
  
  <script setup>
  import { defineProps, onBeforeMount, ref, defineExpose } from 'vue';
  
  const currentIcon = ref('');
  
  const props = defineProps({
    icon: {
      type: String,
      required: true,
    },
    linktext: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
  });
  
  const buttonRef = ref(null); // Referenz für den Button
  
  // Funktion, um den Fokus auf den Button zu setzen
  const focusButton = () => {
    if (buttonRef.value) {
      buttonRef.value.focus();
    }
  };
  
  // Fokusfunktion nach außen hin verfügbar machen
  defineExpose({
    focusButton,
  });
  
  onBeforeMount(() => initialize());
  const variant = ref('')
  
  const initialize = () => {
    currentIcon.value = props.icon;
    variant.value = props.variant;
  };
  </script>
  