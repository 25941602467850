
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  //currentUser
  
} from "firebase/auth";

import { addDoc, collection, doc, setDoc, updateDoc, Timestamp } from "firebase/firestore";
import calenderCore from "../../composition/calenderCore";
import { db } from "../../db";
import axios from 'axios';

export default {
  namespaced: true,
  
  state() {
    return {
      data: null,
      auth: {
        isProcessing: false,
        error: ""
      },
      userID: null
      
    }
  },



  data(){
    return {
      calenderCore1 : calenderCore()
    }
  },

  getters: {
    isAuthenticated(state) {
      return !!state.data;
    },
    currentUserID(state){
      return !!state.userID
    },
    data(state){
      return !!state.data
    }
  },

  actions: {

    onAuthChange({dispatch, commit}, callback) {
      
      commit("setAuthIsProcessing", true);
      onAuthStateChanged(getAuth(), async (user) => {
        if (user) {
          await dispatch('getUserProfile', user);
          commit("setAuthIsProcessing", false);
          callback(user);
        } else {
          commit("setAuthIsProcessing", false);
          callback(null);
        }
      })
      
    },

    async updateProfile({commit, dispatch}, { data, onSuccess }) {
      const userRef = doc(db, "users", data.id);
      await updateDoc(userRef, data);
      commit("updateProfile", data);
      dispatch("toast/success", "Profile has been updated!", {root: true});
      onSuccess();
    },

    async getUserProfile({commit}, user) {
      
     // const docRef = doc(db, "users", user.uid);
     // const docSnap = await getDoc(docRef);
     // const userProfile = docSnap.data();
     // const useWithProfile = {
     //   id: user.uid,
     //   email: user.email,
     //   ...userProfile
      //}
      //commit("setUser", useWithProfile);
      commit("setUser", user);
    },

    async register({commit, dispatch}, {email, password, username}) {

      commit("setAuthIsProcessing", true);
      commit("setAuthError", "");

      try {
        const userCredentials = await createUserWithEmailAndPassword(getAuth(), email, password, username);
        
        await dispatch("createUserProfile", {
          id: userCredentials.user.uid,
          email,
         username
        })

      } catch(e) {
        commit("setAuthError", e.message);
        dispatch("toast/error", e.message, {root: true});
        console.log("Fehler: " + e.message)
      } finally {
        commit("setAuthIsProcessing", false);
      }
    },

    async login({commit, dispatch}, {email, password}) {
      
      commit("setAuthIsProcessing", true);
      commit("setAuthError", "");

      try {
        await signInWithEmailAndPassword(getAuth(), email, password);
        
      } catch(e) {
        commit("setAuthError", e.message);
        dispatch("toast/error", e.message, {root: true});
      } finally {
        commit("setAuthIsProcessing", false);
      }
    },

    async logout({commit}) {
     try {
      await signOut(getAuth());
      commit("setUser", null);
   
     } catch(e) {
      console.error("Error: " + e);
     }
    },

  

    async createCalendarAfterRegister(commit, data) {  

      let newCalendarData = {
          //imageURL: data.icon, 
          MyCalendarsdocId: '',
          calendarId: '',
      }
      
     // if(data.calendarImage != null && data.calendarImage != ''){
          
         // const storage = getStorage()
          
          //--------------- UPLAD IMAGE TO FIREBASE STORAGE START -------------------------------

         // const storageRef = ref(storage, 'users/' + data.creatorUID + '/calendarImages/' + uuidv4() + '_' + data.icon );
         // await uploadBytes(storageRef,data.calendarImage).then(async ()=> {
         //     await getDownloadURL(storageRef)
         //     .then((url) => {
         //         newCalendarData.imageURL = url
          //    return url
          //    })
         // })

      //--------------- UPLAD IMAGE TO FIREBASE STORAGE END -------------------------------
      //}
      
        

      //--------------- STORE NEW CALENDAR TO FIRESTORE START -------------------------------
       
      let newCalendar = {
          color: data.color, 
          icon: data.icon,
          name: data.name,
          description: data.description,
          creatorName: data.creatorName,
          creatorUID: data.creatorUID,
          createdOn: Timestamp.now(),
          editedOn: Timestamp.now(),
          subscribers: 0,
          private: data.private,
          type: data.type,
          country: data.country,
          specialday:data.specialday,
          subdivision:data.subdivision,
          subdivisionCode: data.subdivisionCode,
          externalCalendarLink: data.externalCalendarLink,
          loadedPublicHolidays: false
      }

      await addDoc(collection(db, "calendars"), newCalendar)
      .then(
           async (docRef) => {
              await updateDoc(doc(db, 'calendars', docRef.id),{
                  calendarId : docRef.id,
              });

              newCalendarData.calendarId = docRef.id

              const docRef2 = await addDoc(collection(collection(db, 'users'), data.creatorUID, 'myCalendars'), {
                          calendarId : docRef.id,
                          color: data.color, 
                          icon: data.icon,
                          name: data.name,
                          visible: data.visible,
                          private: data.private,
                          description: data.description,
                          creatorName: "Annucal",
                          createdOn: Timestamp.now(),
                          editedOn: Timestamp.now(),
                          creatorUID: data.creatorUID,
                          subscribers: 0,
                          events:[],
                          type:data.type,
                          externalCalendarLink: data.externalCalendarLink,
                          specialday: data.specialday,
                          country: data.country,
                          subdivision: data.subdivision,
                          subdivisionCode: data.subdivisionCode,
                          loadedPublicHolidays: data.loadedPublicHolidays
                      });
                      newCalendarData.MyCalendarsdocId = docRef2.id

                      
                  }).
                  catch(function(error) {
                      console.error("Error adding document: ", error);
                  })

                
          
          return newCalendarData
  },

    //----------------------------------------------------------------
    //------------------- Set Show Calendar Week ---------------------
    //----------------------------------------------------------------

    async setShowCalendarWeek( commit,newData) {
      try {
          const userDocRef = doc(db, "users", newData.creatorUID);
          await updateDoc(userDocRef, { calendarweek: newData.kw });
      } catch(e) {
        console.log("Fehler: " + e.message)
      } 
    },

    //----------------------------------------------------------------
    //------------------- Set Show Event title ---------------------
    //----------------------------------------------------------------

    async setEventTitle( commit,newData) {
      try {
          const userDocRef = doc(db, "users", newData.creatorUID);
          await updateDoc(userDocRef, { eventTitle: newData.et });
      } catch(e) {
        console.log("Fehler: " + e.message)
      } 
    },

    //----------------------------------------------------------------
    //------------------- Set Show Current day ---------------------
    //----------------------------------------------------------------

    async setShowCurrentDay( commit,newData) {
      try {
          const userDocRef = doc(db, "users", newData.creatorUID);
          await updateDoc(userDocRef, { currentDay: newData.cd });
      } catch(e) {
        console.log("Fehler: " + e.message)
      } 
    },

    //----------------------------------------------------------------
    //------------------- Set language ---------------------
    //----------------------------------------------------------------

    async setLanguage( commit,newData) {
      try {
          const userDocRef = doc(db, "users", newData.creatorUID);
          await updateDoc(userDocRef, { lang: newData.lang });
      } catch(e) {
        console.log("Fehler: " + e.message)
      } 
    },

    //----------------------------------------------------------------
    //------------- Create Userprofile after register ----------------
    //----------------------------------------------------------------

    async createUserProfile(context, {id, ...profile}) {
      await setDoc(doc(db, "users", id), profile)
      .then(() => {
        const url = "https://generatehmac-bakazwh5eq-uc.a.run.app?userId=" + id
        return axios.get(url);
      })
      .then(response => {
        const hmac = response.data.hmac;
        const userDocRef = doc(db, "users", id);
        return updateDoc(userDocRef, { Intercomhmac: hmac });
      })
    .catch(function(error) {
        console.log("Error adding document: ", error);
    });
    }
  },


  
  mutations: {

    setAuthIsProcessing(state, isProcessing) {
      state.auth.isProcessing = isProcessing;
      //store.state.settings.savingModus = false;
    },
    setAuthError(state, error) {
      state.auth.error = error;
    },
    setUser(state, user) {
      state.data = user;
      state.userID = user.uid;
    },
    updateProfile(state, profile) {
      state.data = {...state.data, ...profile};
    }
  }
}