<template>
<div :class="$store.state.settings.currentTheme + '-theme'">
    <!-------------------------------------------------------------------------------------------->
    <!---------------------------------------- Log In  ------------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div style="
            margin-right: -32px;
            margin-top: -4px;
        ">
        <AnnucalVCard
            :isMenu = true
            :first_column_width='0'
            :calendarColor = props.calendarColor
            :showCalendarName = true
            :showvCardActions = false
            :VcardWitdh = '190'
            :calendarName=topheadline
            @close-menu="closeMenu"
        >
            <!-------------------------------------------------------------------------------------------->
            <!------------------------------  Middle Slot - Annucal VCard -------------------------------->
            <!-------------------------------------------------------------------------------------------->

            <template v-slot:middle>
                
                <!--------------------------------  Register --------------------------------------> 

                    <div v-if="isAuthenticated  == false">
                        <AnnucalLink 
                            variant="text"
                            style="padding-top: 4px"
                            icon="PhSignIn"
                            linktext="Register" 
                            @click="openRegisterDialog"
                        />
                            
                        <v-dialog v-model="registerDialog" >
                            <annucal-sign-in
                            :showCalendarName = true
                            @close-menu="closeSignInMenu"
                            :creatorUID=this.$store.state.user.userID
                            prop_mode="Register"
                            :Headline = registerHeadline
                            @closeDialog="closeregisterDialog"
                            
                        />
                        </v-dialog>
                      
                    </div>   

                <!--------------------------------  Login -------------------------------------->
                
                <div v-if="isAuthenticated  == false">
                        <AnnucalLink 
                            variant="text"
                            style="padding-top: 4px"
                            icon="PhUserCircle"
                            linktext="login" 
                            @click="openloginDialog"
                        />
                            
                        <v-dialog v-model="loginDialog" >
                            <annucal-sign-in
                            :showCalendarName = true
                            @close-menu="closeSignInMenu"
                            :creatorUID=this.$store.state.user.userID
                            prop_mode="login"
                            :Headline = loginHeadline
                            @closeDialog="closeloginDialog"
                        />
                        </v-dialog>
                      
                    </div>

                <!--------------------------------  Log Out --------------------------------------> 
                <div v-if="isAuthenticated  == true">
                        <AnnucalLink 
                        variant="text"
                        style="padding-top: 12px"
                        icon="PhSignOut"
                        linktext="logout" 
                        @click="openLogoutDialog"
                    />
                        
                    <v-dialog v-model="loginDialog" >
                        <annucal-sign-in
                        :showCalendarName = true
                        @close-menu="closeSignInMenu"
                        :creatorUID=this.$store.state.user.userID
                        prop_mode="logout"
                        :Headline = logoutHeadline
                        @closeDialog="closeloginDialog"
                    />
                    </v-dialog>
                  
               
                </div>   
                
                <!-------------------------------  Settings ------------------------------------>

                <div style="padding-top: 4px">
                    <AnnucalLink
                        variant="text"
                        icon="PhGear"
                        linktext= "settings" 
                        @click="openSettingsDialog"
                    />
                    <v-dialog 
                        v-model="settingsDialog" 
                        max-width="600">
                        <annucal-settings @closeDialog="closeSettingsDialog"/>
                        </v-dialog>
                </div>

                  <!-------------------------------  About ------------------------------------>

                  <div style="padding-top: 4px">
                    <AnnucalLink
                        variant="text"
                        icon="PhInfo"
                        linktext= "about" 
                        @click="navigateToExternal()"
                    />
               
                </div>

            </template>

        </AnnucalVCard>
    </div>
</div>

</template>

<script setup>

import { defineProps, defineEmits, ref, computed, onMounted, onActivated} from 'vue'
import {useStore} from "vuex";
import AnnucalVCard from "./AnnucalVcard.vue"
import AnnucalLink from "./AnnucalLink.vue"
import AnnucalSignIn from "./AnnucalSignIn.vue"
import AnnucalSettings from './AnnucalSettings.vue';
import { useI18n } from 'vue-i18n'



const { t } = useI18n()

const settingsDialog = ref(false)
const loginDialog = ref(false)
const registerDialog = ref(false)

const topheadline = ref(t('menu') )
const loginHeadline = ref(t('login') )
const logoutHeadline = ref(t('logout') )
const registerHeadline = ref(t('Register'))

function navigateToExternal() {
  window.location.href = 'https://www.annucal.com'; // Externe URL
}


function openSettingsDialog() {
    settingsDialog.value = true;
    state.settings.AnnucalVcardHeadline = t("settings")
    topheadline.value = t("settings")
}

function openloginDialog(){
    state.settings.AnnucalSignInPageVisible = true
    state.settings.AnnucalVcardHeadline = t("login")
    state.settings.AnnucalSaveText = t("login")
    state.settings.Log_Status = "login"
    state.settings.showRegister = false
    state.settings.showForgotPassword = false
    loginDialog.value = true;
    topheadline.value = t("login")
}

function openRegisterDialog(){
    state.settings.AnnucalSignInPageVisible = true
    state.settings.AnnucalVcardHeadline = t("Register")
    state.settings.AnnucalSaveText = t("Register")
    state.settings.Log_Status = "register"
    state.settings.showRegister = true
    state.settings.showForgotPassword = false
    registerDialog.value = true;
    topheadline.value = t("Register")
}

function openLogoutDialog(){
    state.settings.AnnucalSignInPageVisible = true
    state.settings.AnnucalVcardHeadline = t("logout")
    state.settings.AnnucalSaveText = t("logout")
    state.settings.Log_Status = "logout"
    state.settings.showRegister = false
    state.settings.showForgotPassword = false
    loginDialog.value = true;
}  

function closeregisterDialog(){
    registerDialog.value = false
    closeMenu()
}

function closeloginDialog(){
    loginDialog.value = false;
    closeMenu()
}

function closeSettingsDialog() {
    settingsDialog.value = false;
    closeMenu()
}

const store = useStore();
const { state } = store;

const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);

const props = defineProps({
    creatorUID:{
        type: String,
        required: true
    }
});

onActivated(() => initialize());

onMounted(() => initialize());

const initialize = () => {
    store.state.settings.AnnucalVcardHeadline = t("menu")
}   

const emit = defineEmits(['close-menu'])

function closeMenu(){
    emit('close-menu')
}

function closeSignInMenu(){
    signInmenu.value = false
}

const signInmenu = ref(false);

</script>