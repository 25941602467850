

import { createWebHistory, createRouter } from "vue-router";
import { getAuth } from "firebase/auth";
import App from '../App.vue';

const routes = [
  {
    path: '/', // Dynamischer Parameter
    name: 'Home',
    component: App,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, _, next) => {
  const isAuth = !!getAuth().currentUser;

  if (to.meta.onlyAuthUser) {
 
    if (isAuth) {
      next();
    } else {
      document.title =  "Standard-Titel1";
      next({name: "Login"});
    }
  } else if (to.meta.onlyGuestUser) {
    document.title =  "Standard-Titel2";
    next();
  }else{
    document.title = getAuth().currentUser? " AnnuCal | " +   getAuth().currentUser.email :  "AnnuCal | Start planning–it's free!" ;
    next();
  }
})

export default router;