<template>


    <!-------------------------------------------------------------------------------------------->
    <!------------------------------------ Annucal VCard ----------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div style="
            margin-right: -32px;
            margin-top: -4px;
        ">
        <AnnucalVCard
            
            :showCalendarName = true
            :showvCardActions = true
            :VcardWitdh = '500'
            :calendarName="Settings"
            @close-menu="closeMenu"
            @savesettings="savesettings"
            

        >
            <!-------------------------------------------------------------------------------------------->
            <!------------------------------  Middle Slot - Annucal VCard -------------------------------->
            <!-------------------------------------------------------------------------------------------->

            <template v-slot:middle>

                <v-divider style="margin-top:8px"></v-divider>


                <div class="main-grid" style="padding-top: 12px;">
                    <div>
                        <AnnucalLink
                            variant="text"
                            icon="PhGlobe"
                            :linktext=languageText
                            @click="openLanguage"
                        />   
                       <!-- <AnnucalLink
                            variant="text"
                            icon="PhCalendar"
                            :linktext=viewText 
                            @click="openView"
                        /> -->
                        <AnnucalLink
                            variant="text"
                            icon="PhUserCircle"
                            :linktext=accountText
                            @click="openAccount"
                        /> 
                    </div>
                    <div>
                        <div 
                            v-if="currentSettings === 'language'"
                            style="padding-bottom: 12px;"
                            >
                            <form>
                                <!--<div class="control" style="text-align: left;padding-top: 3px;padding-left: 0px;">
                                    <select v-model="$i18n.locale">
                                    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
                                        {{ locale }}
                                    </option>
                                    </select>   
                                </div>-->
                                <div class="annucal-text-16px-bold">{{selectlanguageText}}</div>
                                <div class="control" style="text-align: left;padding-top: 20px;padding-left: 0px;">
                                   
                                <AnnucalRadioButton v-model="selectedLocale" value="de"><div class="annucal-default-text-style">&nbsp;&nbsp;Deutsch</div></AnnucalRadioButton>
                                <AnnucalRadioButton v-model="selectedLocale" value="en"><div class="annucal-default-text-style">&nbsp;&nbsp;English</div></AnnucalRadioButton>
                                       
                                <div>&nbsp;</div>
                                </div>
                            </form>
                        </div>
                        <!--<div v-if="currentSettings === 'view'" style="padding-bottom: 11px;">
                            <div class="headline-text-style prevent-select">{{viewText}}</div>
                            
                            <AnncalCheckbox v-model="showCalendarWeekChecked">
                                <div class="text-style prevent-select">
                                    &nbsp;&nbsp;{{showcalendarweekText}}
                                </div>
                            </AnncalCheckbox>
       
                            <div class="prevent-select">&nbsp;</div>
                            <div class="prevent-select">&nbsp;</div>
                            <div class="prevent-select">&nbsp;</div>
                        </div>-->
                        <div v-if="currentSettings === 'account'" style="padding-bottom: 35px;">
                            <div class="annucal-text-16px-bold">{{ signedinasText }}</div>
                            <div style="padding-top: 20px;" class="annucal-default-text-style" v-if="!isAuthenticated">{{notsignedinText}}</div>
                            <div class="annucal-default-text-style" v-if="isAuthenticated">{{ user.email }}</div>
                            <div class="prevent-select">&nbsp;</div>
                            <div class="prevent-select">&nbsp;</div>
                            
                            

                        </div>
                    </div>
                </div>
            </template>
        </AnnucalVCard>
    </div>    
</template>
  
<script setup>


import { defineEmits, onMounted,ref, computed, nextTick } from 'vue'
import AnnucalVCard from "./AnnucalVcard.vue"
import AnnucalLink from "./AnnucalLink.vue"
import { useI18n } from 'vue-i18n'
import AnnucalRadioButton from "@/components/AnnucalRadioButton.vue";
import { useStore } from 'vuex';
import calenderCore from "../composition/calenderCore";
import countriesDataDE from '../assets/countries_with_subdivisions_de.json';
import countriesDataEN from '../assets/countries_with_subdivisions_en.json';
//import AnncalCheckbox from './AnnucalCheckbox.vue';

const calenderCore1 = calenderCore();
const { t } = useI18n()

const { locale } = useI18n()
const selectedLocale = ref('de')
const languageText = ref('language')
//const viewText = t('view')
const accountText = ref('account')
const selectlanguageText = t('selectlanguage')
const savechangesText = t('savechanges')
const signedinasText = t('signedinasText')
const notsignedinText = t('notsignedin')
//const showcalendarweekText = t('showcalendarweek')

const autoFocusButton = ref(null);


onMounted(async () => {
    initialize();
    store.state.settings.AnnucalVcardHeadline = t("settings")
    await nextTick();

    // Klick auf das Element simulieren
    setTimeout(() => {
        if (autoFocusButton.value && autoFocusButton.value.click) {
            autoFocusButton.value.click();
        }
    }, 100); // Kurze Verzögerung
});

//const toast = useToast();

const store = useStore();
const { state } = store;

const showCalendarWeekChecked = ref(false)

//const isProcessing = computed(() => state.user.auth.isProcessing);
const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
const user = computed(() => state.user.data);

//function changeLanguage(lang) {
//  locale.value = lang
//}

//const locales = ["de", "en", "es", "fr", "pt", "it"]



const currentSettings = ref('language')

onMounted(() => initialize());



const initialize = () => {
    store.state.settings.logInEmail=""
    store.state.settings.logInPassword=""
    store.state.settings.logInPasswordConfirmation=""
    store.state.settings.emailErrorMessage = ""
    store.state.settings.savingModus = false
    store.state.settings.AnnucalVcardHeadline = t("settings")
    store.state.settings.logInEmail = ""
    store.state.settings.logInPassword = ""
    store.state.settings.AnnucalSaveText = savechangesText
    store.state.settings.Log_Status = "settings"
    showCalendarWeekChecked.value = store.state.settings.showKalenderwochen
    selectedLocale.value = locale.value
} 

const emit = defineEmits(['closeDialog']);

function closeMenu() {
  emit('closeDialog');
}

const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
})




function removeAllPublicHolidayEvents(){
    // delete event from eventlist
    for(let index = 0; index < store.state.storedevents.items.length; ++index){
        for (let index = store.state.storedevents.items.length - 1; index >= 0; --index){
            if (store.state.storedevents.items[index].type != '1'){
                store.state.storedevents.items.splice(index, 1);
            }
        }
    }
}

//name: Schulferien
//specialday: Schulferien
//subdivision: Burgenland
//country: Österreich
//subdivisionCode: AT-BL

//storedEvents: 
//calendarName: Schulferien
//additionalInfos: Österreich, Burgenland

//function extractWords(input) {
//  const [first, second] = input.split(',').map(word => word.trim());
//  return { first, second };
//}

function getCorrectSubdivisionForOpenHolidays(subdivision){
  switch(subdivision) {
  case 'AT-BL':
    return 'AT-1'
  case 'AT-KÄ':
    return 'AT-2'
  case 'AT-NÖ':
    return 'AT-3'
  case 'AT-OÖ':
    return 'AT-4'
  case 'AT-SB':
    return 'AT-5'
  case 'AT-SM':
    return 'AT-6'
  case 'AT-TI':
    return 'AT-7'
  case 'AT-VA':
    return 'AT-8'
  case 'AT-WI':
    return 'AT-9'
  default:
    // code block
  }
  return subdivision
}



function changeLangueForHolidayCalendars(){
    for(let index = 0; index < store.state.mycalendars.items.length; ++index){//Loop through MyCalendars
        if(store.state.mycalendars.items[index].type != '1'){//If Holiday Calendar
            if(selectedLocale.value == 'en'){
                for(let index2 = 0; index2 < countriesDataEN.length; ++index2){
                    if(countriesDataEN[index2].CountryCode == store.state.mycalendars.items[index].subdivisionCode.slice(0, 2)){
                        for(let index3 = 0; index3 < countriesDataEN[index2].Subdivisions.length; ++index3){
                            if(countriesDataEN[index2].Subdivisions[index3].SC == getCorrectSubdivisionForOpenHolidays(store.state.mycalendars.items[index].subdivisionCode) ){
                                store.state.mycalendars.items[index].country = countriesDataEN[index2].Country
                                store.state.mycalendars.items[index].subdivision = countriesDataEN[index2].Subdivisions[index3].SN
                                store.state.mycalendars.items[index].name = "School holidays"
                                store.state.mycalendars.items[index].specialday = "School holidays"
                            }
                        }
                    }
                }
            }else if(selectedLocale.value == 'de'){
                for(let index2 = 0; index2 < countriesDataEN.length; ++index2){
                    if(countriesDataDE[index2].CountryCode == store.state.mycalendars.items[index].subdivisionCode.slice(0, 2)){
                        for(let index3 = 0; index3 < countriesDataDE[index2].Subdivisions.length; ++index3){
                            if(countriesDataDE[index2].Subdivisions[index3].SC == getCorrectSubdivisionForOpenHolidays(store.state.mycalendars.items[index].subdivisionCode) ){
                                store.state.mycalendars.items[index].country = countriesDataDE[index2].Country
                                store.state.mycalendars.items[index].subdivision = countriesDataDE[index2].Subdivisions[index3].SN
                                store.state.mycalendars.items[index].name = "Schulferien"
                                store.state.mycalendars.items[index].specialday = "Schulferien"
                            }
                        }
                    }
                }
            }    
            
        }
    }
}

const savesettings = async () => {
    state.settings.savingModus = true
    store.state.settings.showKalenderwochen = showCalendarWeekChecked.value
    locale.value = selectedLocale.value

//--------------- CHANGE LOCAL MYCALENDERS END ---------------------------

removeAllPublicHolidayEvents()
store.dispatch("removeAllPuplicCalendarsFromList", "ChangeLanguage")

changeLangueForHolidayCalendars()

await store.dispatch('getPublicHolidaysFromApi', { userId: store.state.user.userID, localCalendars: mycalendars.value, language: ref(t('selectedlanguage') ) });
    //await store.dispatch("getPublicHolidaysFromApi", store.state.user.userID, store.state.mycalendars.items)
    //store.state.mycalendars.items = store.state.storedmycalendars.items
    store.state.availablecalendars.items = store.state.storedavailablecalendars.items
    store.state.events.items = store.state.storedevents.items
    await store.dispatch("getmydaysWithEventsFromDB", {
        events: store.state.events.items, 
        myCalendars: mycalendars, 
        calender: store.state.calender.items
    })

       // Refresh Events Indexlist
    for(let index = 0; index < store.state.events.items.length; ++index){
        store.state.events.items[index].index = index;
    }

    // Write each event index in the events of each mycalendars
    for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
      store.state.mycalendars.items[calenderIndex].events = []
        for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
            if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
              store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
            }
        } 
    } 

    

    calenderCore1.updateCalender()

    if(state.user.userID != null){
            await store.dispatch('user/setLanguage', {
            creatorUID:state.user.userID,
            lang:selectedLocale.value
          })
        }
    

    closeMenu()
    state.settings.savingModus = false
}

function openLanguage(){
    currentSettings.value = "language"
}

function openAccount(){
    currentSettings.value = "account"
}

//function openView(){
//    currentSettings.value = "view"
//}




</script>

  
<style scoped>

.main-grid{
    display: grid;
    grid-template-columns:200px 1fr;
}

.text-grid{
    padding-bottom:16px;
    float: right;
    margin-left: auto;
    display: inline-grid;
    font-size: 12px;
    grid-template-columns: 1fr 1fr;
    letter-spacing: -0.5px;
}

.text-grid-left{
    font-weight: 300;
    text-align: right;
    
}

.text-grid-right{
    font-weight: 400;
    box-sizing: border-box;
    overflow: hidden; 
    transition: transform 0.2s ease-in-out; 
    width: 100%; 
    text-align: right; 
    
}

.text-grid-right:hover{
    transform: scale(1.02); 
    color: black;
    font-weight: 500;
    cursor: pointer;
}

.button-text-cancel{
  font-size: 15px;
  font-weight: 400;
  color: #615E59;
  letter-spacing: -0.6px;
  text-align: right;
  margin-right: 2px;
  padding-left:-44px;
}

.button-text-cancel:hover{
  background-color: #F6F2EF;
}

.close-button {
  font-size: 13px;
  font-weight: 500;
  color: #615E59;
  letter-spacing: -0.6px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover{
    background-color: #F6F2EF;

}

.link-text{

transition: transform 0.2s ease-in-out;
}

.link-text:hover{

color: #191816;
cursor: pointer;
}


.info-text{
  font-size: 16px;
  font-weight: 300;
  color: #615E59;
  letter-spacing: -0.8px;
  text-align: left;
  margin-right: 2px;
  user-select: none;
}

.user-email-text{
  font-size: 16px;
  font-weight: 600;
  color: #615E59;
  letter-spacing: -0.8px;
  text-align: left;
  margin-right: 2px;
  user-select: none;
}

</style>
  
